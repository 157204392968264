import React from 'react';
import 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker'; // Import the worker directly

const PDFtoImage = () => {
  const handleConvert = () => {
    const pdfInput = document.getElementById('pdf-input');
    const output = document.getElementById('output');

    // Ensure a PDF file has been selected
    if (!pdfInput.files || pdfInput.files.length === 0) {
      return alert('Please select a PDF file to convert');
    }

    // Read the selected PDF file
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      // Convert the PDF to an image using PDF.js
      const pdfData = event.target.result;
      const loadingTask = window.pdfjsLib.getDocument({ data: pdfData });
      loadingTask.promise.then((pdf) => {
        pdf.getPage(1).then((page) => {
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderTask = page.render({ canvasContext: ctx, viewport });
          renderTask.promise.then(() => {
            // Output the image as a data URL
            const imageUrl = canvas.toDataURL('image/png');
            const imageElement = document.createElement('img');
            imageElement.src = imageUrl;
            output.innerHTML = '';
            output.appendChild(imageElement);
          });
        });
      });
    };
    fileReader.readAsArrayBuffer(pdfInput.files[0]);
  };

  const handleDownload = () => {
    // Get the image element
    const imageElement = document.querySelector('#output img');
    if (!imageElement) {
      return;
    }

    // Create a download link
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = imageElement.src;

    // Trigger the download
    link.click();
  };

  return (
    <section>
      <div>
        <h1 className='text-3xl font-bold mt-2 text-center'>PDF to Image</h1>
      </div>
      <div className='text-center mt-5 mb-5'>
        <input type="file" name="" id="pdf-input" />
      </div>
      <div className='text-center mt-2 mb-2'>
        <button onClick={handleConvert} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>
          Convert to Image
        </button>
      </div>
      <div id="output" className='text-center mt-5 mb-2'></div>
      <div className='text-center mt-2 mb-2'>
        <button onClick={handleDownload} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>
          Download Image
        </button>
      </div>
      <div className='ml-2 mb-5 mt-5'>
        <p className='text-xl'>
          
Introducing our PDF to Image Converter, a cutting-edge tool tailored to streamline your web development projects and enhance your capabilities as a burgeoning web developer. As you delve into the intricacies of React app development, this tool proves to be an invaluable asset, facilitating seamless integration of dynamic visual content into your applications.
        </p>
        <br />
        <p className='text-xl'>
        Our PDF to Image Converter harnesses advanced AI technologies to simplify the process of converting PDF documents into high-quality images. As a web developer with a foundation in HTML, CSS, JavaScript, and React, this tool aligns perfectly with your skill set, allowing you to effortlessly incorporate striking visuals into your projects.
        </p>
        <br />
        <p className='text-xl'>
        Navigating the complexities of web development often involves handling diverse file formats, and our converter tool is here to simplify the integration of PDF content into your applications. By seamlessly converting PDFs into images, you can elevate the user experience on your React applications, offering a visually engaging interface that captures the attention of your audience.

 
        </p>
        <br />
        <p className='text-xl'>
        The integration of this tool is designed to be user-friendly, ensuring that even as a beginner web developer, you can effortlessly incorporate PDF to image conversion into your projects. Whether you're looking to enhance the visual appeal of your websites or dynamically display content on your React applications, our PDF to Image Converter empowers you to do so with ease.

Embark on this AI-assisted journey
        </p>
      </div>
    </section>
  );
};

export default PDFtoImage;
