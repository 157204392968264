import React, { useState } from 'react';

const JPGtoPNGConverter = () => {
    const [file, setFile] = useState(null);
  const [convertedBlob, setConvertedBlob] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const convertToPng = () => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            setConvertedBlob(blob);
          }, 'image/png');
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    if (convertedBlob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(convertedBlob);
      link.download = 'converted_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <section>
    <div className='flex flex-col justify-center items-center gap-5 mt-2'>
      <h1 className='text-3xl font-bold'>JPG to PNG Converter</h1>
      <input type="file" accept=".jpg" onChange={handleFileChange} />
      <button onClick={convertToPng} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Convert to PNG</button>

      {convertedBlob && (
        <div className='flex flex-col items-center justify-center gap-5'>
\          <img className='w-[400px] rounded-lg' src={URL.createObjectURL(convertedBlob)} alt="Converted" style={{ maxWidth: '100%' }} />
          <button onClick={handleDownload} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Download Converted Image</button>
        </div>
      )}
    </div>
    <div className='ml-2 mt-5 mb-5'>
      <p className='text-xl'>
      Introducing our innovative JPG to PNG Converter tool, a user-friendly solution designed to simplify your image conversion needs. Whether you're a graphic designer, photographer, or simply an individual looking to enhance your visual content, our tool empowers you to effortlessly convert JPG images to PNG format without the need for external APIs or complex software.
      </p>
      <br />
      <p className='text-xl'>
      With a sleek and intuitive user interface, our tool ensures a seamless experience. Just upload your JPG file using the easy-to-navigate interface, click the "Convert to PNG" button, and witness the transformation in real-time. The tool utilizes client-side processing, leveraging the power of your browser to efficiently convert the images on-the-fly.
      </p>
      <br />
      <p className='text-xl'>
      Once the conversion is complete, preview the converted PNG image directly on the screen. We understand the importance of user convenience, and that's why we've incorporated a "Download Converted Image" button, allowing you to instantly save the newly created PNG file to your device.
      </p>
      <br />
      <p className='text-xl'>Say goodbye to the hassle of searching for external conversion services or dealing with complicated software installations. Our JPG to PNG Converter puts the control in your hands, providing a quick and reliable solution for all your image format conversion needs. Experience the simplicity and efficiency of our tool today and elevate your image processing workflow with ease.</p>
    </div>
    </section>
  );
};

export default JPGtoPNGConverter;
