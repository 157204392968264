import React, { useEffect } from 'react';

const ArticleRewriter = () => {
  
    const serverURL = "https://api.funtranslations.com/translate/article_rewrite.json";

    useEffect(() => {
        const textInput = document.querySelector("#txt-input");
        const btnParaphrase = document.querySelector("#btn-paraphrase");
        const outputDiv = document.querySelector("#output");
    
        function getTranslationURL(text) {
          return `${serverURL}?text=${text}`;
        }
    
        function errorHandler(error) {
          console.log("Error Occurred : ", error);
          alert(`Something went wrong from the server side... Please try again after some time`);
        }
    
        function clickHandler() {
          const url = getTranslationURL(textInput.value);
          fetch(url)
            .then(response => response.json())
            .then(json => {
              const translatedText = json.contents.translated;
              outputDiv.innerText = translatedText;
            })
            .catch(errorHandler)
        }
    
        btnParaphrase.addEventListener("click", clickHandler);
    
        return () => {
          btnParaphrase.removeEventListener("click", clickHandler);
        };
      }, []); 
  return (
    <section className='flex flex-col'>
        <div>
            <h1 className='text-3xl font-semibold text-center mt-5 mb-5'>Article Rewriter</h1>
        </div>
        <div>
            <textarea name="" placeholder='Enter your Article'   className=' border-[2px] w-[95%] ml-2 sm:w-[60%] sm:ml-[20%] px-2 py-1 rounded border-[#001f3f]' id="txt-input" cols="30" rows="10"></textarea>
        </div>

        <div className='text-center mt-5 mb-5'>
            <button id="btn-paraphrase"  className='text-white bg-blue-500 hover:bg-blue-600 px-3 py-2 rounded-lg'>Rewrite</button>
        </div>

        <div className='mt-5 mb-5 text-xl ml-2' id="output">

        </div>
        
        <div className='ml-2 mb-5'>
            <h1 className='text-3xl font-bold'>Revolutionize Your Content with Our Article Rewriter Tool</h1>

            <h2 className='text-2xl font-semibold mt-5'>Unleash the Power of Paraphrasing with Ease</h2>
            <p className='text-xl mt-2'>In the ever-evolving landscape of online content, keeping your articles fresh and engaging is crucial. Introducing our cutting-edge Article Rewriter Tool, designed to revolutionize the way you approach content creation. Say goodbye to redundant sentences and welcome a new era of creativity and uniqueness.</p>

            <h2 className='text-2xl font-semibold mt-5'> Seamless Integration with Advanced Technology</h2>
            <p className='text-xl mt-2'>
            Our tool leverages the latest advancements in natural language processing, ensuring seamless and accurate paraphrasing. Powered by state-of-the-art algorithms, it goes beyond basic synonyms, intelligently restructuring sentences to maintain context and coherence.
            </p>

            <h2 className='text-2xl font-semibold mt-5'>
            Intuitive User Interface for Effortless Rewriting
            </h2>

            <p className='text-xl mt-2'>
            Designed with user experience in mind, our tool features an intuitive interface that caters to both beginners and seasoned writers. Simply input your article, click a button, and witness the magic unfold. It's rewriting made easy.
            </p>

            <h2 className='text-2xl font-semibold mt-5'>
            Boost SEO Performance
            </h2>
            <p className='text-xl mt-2'>
            Search engines reward fresh, original content. Our Article Rewriter Tool enhances your SEO efforts by generating unique variations of your articles. This not only pleases search engine algorithms but also captivates your audience with diverse perspectives.
            </p>
        </div>
    </section>
  )
}

export default ArticleRewriter