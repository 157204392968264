import React, { useEffect } from 'react';
import $ from 'jquery';

const QRCodeMaker = () => {

    useEffect(() => {
        $('#close-qr-error').on("click", function(){
            $('#qr-error-msg').hide();
        });

        $('#qr-call-btn').on('click', function () {
            if ($('#qr-input-content').val() !== '') {
                $('#qr-error-msg').hide();
                $('#qr-download-section').empty();
                let finalURL = 'https://chart.googleapis.com/chart?cht=qr&chl=' + chartCall($('#qr-input-content').val()) + '&chs=200x200&chld=L|0'; 
                var qrImg = "<img src='' class='qr-code' />";
                $('#qr-download-section').append(qrImg);
                $('.qr-code').attr('src', finalURL); 
                var qrSpace = "<p><br></p>";
                var qrDwnldBtn = "<a href=" + finalURL + "> <button id='qr-download-btn' type='button' class='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg text-sm'>Download</button> </a>";
                $('#qr-download-section').append(qrSpace,qrDwnldBtn);
            }
            else {
                $('#qr-download-section').empty();
                $('#qr-error-msg').show();
            }
        });
    }, []); 

    function chartCall(value) { 
        return $('<div/>').text(value).html(); 
    } 

    return (
        <section className="flex flex-col items-center justify-center">
            <script src="https://code.jquery.com/jquery-3.5.1.js"></script> 

            <div>
                <h1 className='text-3xl font-bold text-center mt-5'>QR Code Maker</h1>
            </div>
            <div className='text-center mt-5'>
                <input type="text" className='w-[300px] border-2 rounded-lg px-2 py-2' placeholder='Enter your text/link' name="" id="qr-input-content" required />
            </div>
            <div className='text-center mb-5 mt-5'>
                <button id="qr-call-btn" className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Generate</button>
            </div>
            <div className='text-center mb-5' id="qr-download-section"></div>
			<div className='ml-2 mt-10 mb-20'>
				<p className='text-xl'>
					
Introducing the QR Code Maker, a powerful and user-friendly tool that empowers you to generate customized QR codes effortlessly. Seamlessly designed for efficiency, this tool is tailored for users like you, a budding web developer eager to enhance projects with dynamic QR code functionality.
				</p>
				<br />
				<p className='text-xl'>
				With a clean and intuitive interface, the QR Code Maker simplifies the process of creating QR codes for your URLs, texts, or links. Enter your desired content in the provided input field, click the "Generate" button, and witness the magic unfold. The tool dynamically generates QR codes using the Google Chart API, ensuring reliability and swift creation.
				</p>
				<br />
				<p className='text-xl'>
				Not only does the QR Code Maker focus on functionality, but it also prioritizes user experience. Clear error messages guide you through the process, and the tool offers a sleek download button for easy access to your created QR code. The responsive design ensures a seamless experience across devices.
				</p>
			</div>
        </section>
    )
}

export default QRCodeMaker;
