import React from 'react';
import './ResumeMaker.css'

const ResumeMaker = () => {
    function addNewWEField() {
        let newNode=document.createElement('textarea');
        newNode.classList.add('form-control');
        newNode.classList.add('newField');
        
        newNode.setAttribute("rows",3);
        

        let weOb=document.getElementById("we");

        let weAddButtonOb=document.getElementById('weAddButton');

        weOb.insertBefore(newNode, weAddButtonOb);
     
    }

    function addNewAQField()
    {
        let newNode=document.createElement('textarea');
        newNode.classList.add('form-control');
        newNode.classList.add('eqField');
        
        newNode.setAttribute("rows",3);
        

        let aqOb=document.getElementById("aq");

        let aqAddButtonOb=document.getElementById('aqAddButton');

        aqOb.insertBefore(newNode, aqAddButtonOb);
    }

    //Generating CV

    function generateCV() {
        let nameField=document.getElementById('nameField').value;
        let nameT1=document.getElementById('nameT1');

        nameT1.innerHTML = nameField;

        //nameT2

        document.getElementById('nameT2').innerHTML=nameField;

        //contact

        document.getElementById('contactT').innerHTML = document.getElementById("contactField").value;

        //email
        document.getElementById('emailT').innerHTML = document.getElementById("emailField").value;

        //address

        document.getElementById('addressT').innerHTML = document.getElementById("addressField").value;

       

        //links

        document.getElementById('fbT').innerHTML = document.getElementById("fbField").value;
        document.getElementById('instaT').innerHTML = document.getElementById("instaField").value;
        

        //objective

        document.getElementById('ObjectiveT').innerHTML = document.getElementById("ObjectiveField").value;

        

        //work experience

        document.getElementById('newT').innerHTML = document.getElementById("newfield").value;




        //Academic Qualification

        document.getElementById('aqT').innerHTML = document.getElementById("eqField").value;

        //New W E
       



        //setting image

        let file=document.getElementById('imgField').files[0];

        console.log(file);

        let reader=new FileReader();

        reader.readAsDataURL(file);

        console.log(reader.result);

        //set image to template

        reader.onloadend=function(){
            document.getElementById("imgTemplate").src=reader.result;
        };


        document.getElementById('cv-form').style.display='none';
        document.getElementById('cv-template').style.display='block';



    }

    //print cv

    function printCV()
    {
        window.print();
    }

  return (
    <div className='mb-5'>
            
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" />

      <div className="container" id="cv-form">
        <h1 className="text-center my-2 h1">Resume Generator</h1>

        <div className="row">

          <div className="col-md-6 h4">
            <h3>Personal Information</h3>
            <div className="form-group">
              <label htmlFor="nameField">Your Name</label>
              <input type="text" id="nameField" placeholder="Enter Here" className="form-control" />
            </div>

            <div className="form-group mt-4">
              <label htmlFor="contactField">Your Contact No.</label>
              <input type="text" id="contactField" placeholder="Enter Here" className="form-control" />
            </div>

            <div className="form-group mt-4">
              <label htmlFor="emailField">Email Address</label>
              <input type="text" id="emailField" placeholder="Enter Here" className="form-control" />
            </div>

            <div className="form-group mt-4">
              <label htmlFor="addressField">Your Address</label>
              <textarea rows="5" id="addressField" placeholder="Enter Here" className="form-control"></textarea>
            </div>

            <div className="form-group mt-4">
              <label htmlFor="imgField">Select Your Photo</label>
              <input id="imgField" type="file" className="form-control" />
            </div>

            <p className="text-secondray mt-4">Important Links</p>

            <div className="form-group mt-4">
              <label htmlFor="fbField">Facebook</label>
              <input type="text" id="fbField" placeholder="Enter Here" className="form-control" />
            </div>

            <div className="form-group mt-4">
              <label htmlFor="instaField">Instagram</label>
              <input type="text" id="instaField" placeholder="Enter Here" className="form-control" />
            </div>
          </div>

          <div className="col md 6 h4">
            <h3>Professional Information</h3>

            <div className="form-group mt-4">
              <label htmlFor="ObjectiveField">Objective</label>
              <textarea id="ObjectiveField" placeholder="Enter Here" rows="5" className="form-control"></textarea>
            </div>

            <div className="form-group mt-4" id="we">
              <label htmlFor="newfield">Work Experience</label>
              <textarea id="newfield" placeholder="Enter Here" rows="3" className="form-control "></textarea>
              <div className="container text-center mt-2" id="weAddButton">
                <button onClick={addNewWEField} className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>

            <div className="form-group mt-4" id="aq">
              <label htmlFor="eqField">Academic Qualification</label>
              <textarea id="eqField" placeholder="Enter Here" rows="3" className="form-control "></textarea>
              <div className="container text-center mt-2" id="aqAddButton">
                <button onClick={addNewAQField} className="btn btn-primary btn-sm">Add</button>
              </div>
            </div>
          </div>

          <div className="container text-center mt-3">
            <button onClick={generateCV} className="btn btn-primary btn-lg">Generate CV</button>
          </div>
        </div>
      </div>

      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" />

      <div className="container" id="cv-template">
        <div className="row">
          <div className="col-md-4 text-center">
            <img src="dataC" alt="" className="img-fluid myimage" id="imgTemplate" />

            <div className="container">
              <p id="nameT1" className="h1">Sohaib Faisal Khan</p>
              <p id="contactT">84353465385</p>
              <p id="emailT">84353465385</p>
              <p id="addressT">hfdbkjfd dk d jdv</p>
             

              <p>
              <a id="fbT" href="https://www.facebook.com/" rel='noreferrer' target="_blank">facebook.com</a>
              </p>
              <p>
              <a id="instaT" href="https://www.instagram.com/" rel='noreferrer' target="_blank">instagram.com</a>
              </p>
            </div>
          </div>

          <div className="col-md-8 py-5">
            <h1 id="nameT2" className="text-center h1" style={{ fontWeight: 900 }}>Sohaib Niazi</h1>

            <div className="card mt-4">
              <div className="card-header">
                <h3>Objective</h3>
              </div>
              <div className="card-body">
                <p id="ObjectiveT">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat dignissimos dolore quo doloribus enim necessitatibus dolorem mollitia rerum tenetur soluta ex vel voluptatum, cupiditate dolores porro esse repellat sint laudantium voluptatibus quas in, quidem ab consectetur? Obcaecati incidunt quis sit labore provident! Provident ab praesentium asperiores nesciunt aliquam obcaecati voluptatem?</p>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header">
                <h3>Work Experience</h3>
              </div>
              <div className="card-body">
                <p id="newT">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia, non!</p>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header">
                <h3>Academic Qualification</h3>
              </div>
              <div className="card-body">
                <p id="aqT">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia, non!</p>
              </div>
            </div>

            <div className="container mt-3 text-center">
              <div onClick={printCV} className="bg-blue-500 text-white py-2 px-2 rounded-full cursor-pointer hover:bg-blue-600">
                Print CV
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ResumeMaker;
