import React, { useState } from 'react';
import axios from 'axios';

const SSLCertificateChecker = () => {
  const [host, setHost] = useState('');
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [error, setError] = useState(null);

  const handleHostChange = (e) => {
    setHost(e.target.value);
  };

  const checkCertificate = async () => {
    try {
      const response = await axios.get('https://ssl-certificate-checker2.p.rapidapi.com/ssl-certificate-checker/check', {
        params: { host },
        headers: {
          'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
          'X-RapidAPI-Host': 'ssl-certificate-checker2.p.rapidapi.com'
        }
      });

      setCertificateInfo(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setCertificateInfo(null);
      setError('Error checking SSL certificate. Please try again.');
    }
  };

  return (
    <section>
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>SSL Certificate Checker</h1>
      <div className='flex flex-col justify-center text-center'>
      <label className='text-xl mt-2 mb-2'>
        Enter the host (e.g., example.com):
        <div>
        <input className='border-2 rounded mt-2 px-2' placeholder='Enter your URL' type="text" value={host} onChange={handleHostChange} />
        </div>
      </label>
      </div>
      <br />
      <div className='text-center mb-5'>
      <button className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg' onClick={checkCertificate}>Check Certificate</button>
      </div>
      {certificateInfo && (
        <div>
          <h3 className='text-center text-2xl'>Certificate Information</h3>
          <div className='ml-2 flex flex-col gap-2'>
          <p className='text-xl'><strong>Subject:</strong> {certificateInfo.subject.CN}</p>
          <p className='text-xl'><strong>Issuer:</strong> {certificateInfo.issuer.C}</p>
          <p className='text-xl'><strong>Valid From:</strong> {certificateInfo.validFrom}</p>
          <p className='text-xl'><strong>Valid To:</strong> {certificateInfo.validTo}</p>
          <p className='text-xl'><strong>Expires In Days:</strong> {certificateInfo.expiresInDays}</p>
          <p className='text-xl'><strong>Fingerprint:</strong> {certificateInfo.fingerprint}</p>
          <p className='text-xl'><strong>Fingerprint256:</strong> {certificateInfo.fingerprint256}</p>
          <p className='text-xl'><strong>Serial Number:</strong> {certificateInfo.serialNumber}</p>
          <p className='text-xl'><strong>PEM:</strong> {certificateInfo.pem}</p>
          <p className='text-xl'><strong>Protocol:</strong> {certificateInfo.protocol}</p>
          <p className='text-xl'><strong>Cipher:</strong> {certificateInfo.cipher.name}</p>
          <p className='text-xl'><strong>SubjectAltNames:</strong> {certificateInfo.subjectAltNames}</p>
          <p className='text-xl'><strong>CA Issuers - URI:</strong> {certificateInfo.infoAccess['CA Issuers - URI']}</p>
          <p className='text-xl'><strong>OCSP - URI:</strong> {certificateInfo.infoAccess['OCSP - URI']}</p>
          </div>
          {/* Include other relevant information here */}
        </div>
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
<hr className='mt-2' />
    <div className='mt-5 mb-5 ml-2'>
        <p className='text-xl'>
        The SSL Certificate Checker tool is a crucial component for web developers and system administrators to ensure the security and reliability of a website. This tool provides a comprehensive overview of the SSL/TLS certificate associated with a specific domain, aiding users in verifying the authenticity and validity of their digital certificates.
        </p>
        <p className='text-xl mt-5'>
        With this SSL Checker, users can effortlessly inspect vital information, including the certificate's subject and issuer details, the period of validity from the issuance date to expiration, and the number of days until it expires. Moreover, the tool reveals essential cryptographic details, such as the fingerprint, protocol, and cipher used to secure the connection.
        </p>
        <p className='text-xl mt-5'>
        This tool simplifies the often complex task of SSL certificate verification, offering a user-friendly interface to quickly assess the security status of a website. By displaying the certificate's Subject Alternative Names (SANs) and access URLs for Certificate Authorities (CAs) and Online Certificate Status Protocol (OCSP), users gain valuable insights into the certificate's configuration.
        </p>
        <p className='text-xl mt-5'>
        Whether for routine monitoring or troubleshooting potential security issues, the SSL Certificate Checker empowers users with the information needed to make informed decisions regarding their website's security posture. With its intuitive design and detailed reporting, this tool serves as an invaluable resource for maintaining a secure and trustworthy online presence.
        </p>
    </div>
    </section>
  );
};

export default SSLCertificateChecker;
