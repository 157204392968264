import React, { useRef, useState } from 'react';

const BackgroundRemover = () => {
  const [loading, setLoading] = useState(false);

  const imageInputRef = useRef(null);
  const outputImageRef = useRef(null);
  const downloadButtonRef = useRef(null);

  const handleSubmit = () => {
    if (!imageInputRef.current.files || !imageInputRef.current.files[0]) {
      alert("Please select an image to remove the background from");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("image_file", imageInputRef.current.files[0]);

    fetch("https://api.remove.bg/v1.0/removebg", {
      method: "POST",
      body: formData,
      headers: {
        "X-Api-Key": "A62r1PcA69YC2onaMye3MqnZ"
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const objectURL = URL.createObjectURL(blob);
        outputImageRef.current.src = objectURL;

        if (downloadButtonRef.current) {
          downloadButtonRef.current.disabled = false;
        }

        setLoading(false);
      })
      .catch(error => {
        alert(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = outputImageRef.current.src;
    downloadLink.download = imageInputRef.current.files[0].name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <section className='flex flex-col justify-center items-center gap-5'>
      <div>
        <input type="file" name="" accept='image/*' id="image-input" ref={imageInputRef} />
        <button className='text-xl text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg mt-5' onClick={handleSubmit}>
          Remove Background
        </button>
      </div>

      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          &nbsp;
          {loading}
        </div>
      )}

      <div>
        <img
          className='w-[400px] rounded-lg'
          ref={outputImageRef}
          src="https://sp-ao.shortpixel.ai/client/to_webp,q_lossless,ret_img,w_1024,h_512/https://martinvorel.com/wp-content/uploads/2022/05/Slazzer.png"
          alt=""
        />
      </div>

      <div>
        <button
          id="download-button"
          className='bg-blue-500 hover:bg-blue-600 text-white text-xl px-2 py-2 rounded-lg'
          onClick={handleDownload}
          ref={downloadButtonRef}
          disabled={loading}
        >
          Download Image
        </button>
      </div>

      <div className='ml-2 mb-5 mt-5'>
        <p className='text-xl'>
          Introducing the Image Background Remover – a powerful tool designed to effortlessly enhance your visual content by eliminating backgrounds with just a few clicks. As a burgeoning web developer, harness the potential of this feature-packed React application to elevate your projects to new heights.
        </p>
        <br />
        <p className='text-xl'>
          With a sleek and intuitive user interface, our Image Background Remover simplifies the process of removing backgrounds from images, offering a seamless experience for users of all levels. Select an image, click the "Remove Background" button, and witness the magic unfold as the tool swiftly extracts the subject, leaving you with a clean, professional result.
        </p>
        <br />
        <p className='text-xl'>
          Built with cutting-edge technology, the remover leverages the Remove.bg API to ensure high-quality, precise background removals. The integration of React ensures a responsive and dynamic user interface, creating a fluid user experience. Additionally, the application allows users to download the edited images effortlessly, providing a convenient solution for incorporating refined visuals into their web projects.
        </p>
      </div>
    </section>
  );
};

export default BackgroundRemover;
