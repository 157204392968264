import React, { useState } from 'react';

const SalaryCalculator = () => {
  const [annualSalary, setAnnualSalary] = useState('');
  const [deductions, setDeductions] = useState('');
  const [bonuses, setBonuses] = useState('');
  const [monthlySalary, setMonthlySalary] = useState('');
  const [netSalary, setNetSalary] = useState('');

  const calculateSalary = () => {
    const annual = parseFloat(annualSalary);
    const deduction = parseFloat(deductions);
    const bonus = parseFloat(bonuses);

    const monthly = (annual + bonus) / 12 - deduction;
    const net = monthly * 0.75; // Assuming 25% tax

    setMonthlySalary(isNaN(monthly) ? '' : monthly.toFixed(2));
    setNetSalary(isNaN(net) ? '' : net.toFixed(2));
  };

  return (
    <div>
      <h1 className="text-3xl font-bold text-center mt-5">Salary Calculator</h1>
      <div className="flex flex-col items-center text-center justify-center gap-4">
        <div>
          <p className='text-xl mt-2 mb-2'>
          Annual Salary:
          </p>
          <div>
          <input className='border-2 w-[300px] px-2 py-1 rounded-lg' type="number" value={annualSalary} onChange={(e) => setAnnualSalary(e.target.value)} />
          </div>
          </div>
        <div>
          <p className='text-xl mt-2 mb-2'>
          Deductions:
          </p>
          <div>
          <input className='border-2 w-[300px] px-2 py-1 rounded-lg' type="number" value={deductions} onChange={(e) => setDeductions(e.target.value)} />
          </div>
          </div>
        <div>
          <p className='text-xl mt-2 mb-2'>
          Bonuses:
          </p>
          <div>
          <input className='border-2 w-[300px] px-2 py-1 rounded-lg' type="number" value={bonuses} onChange={(e) => setBonuses(e.target.value)} />
          </div>
          </div>
        <button onClick={calculateSalary} className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg">
          Calculate Salary
        </button>
        <div className='text-xl flex flex-col gap-2 mb-5'>
          <p>Monthly Salary: {monthlySalary}</p>
          <p>Net Salary: {netSalary}</p>
        </div>
      </div>
      <div className='ml-2 mb-5'>
        <p className='text-xl'>
        The Salary Checker tool is a valuable resource for individuals to assess and understand their income details effectively. This tool allows users to input various components of their salary, such as the annual income, deductions, and bonuses. With a user-friendly interface, it simplifies the process of evaluating the monthly and net income.
        </p>
        <br />
        <p className='text-xl'>
        Users can effortlessly enter their annual salary, providing transparency into their overall earnings. Additionally, deductions and bonuses are factored in to compute the monthly income accurately. The tool facilitates quick and efficient salary calculations, aiding users in managing their finances effectively.
        </p>
        <br />
        <p className='text-xl'>
        Furthermore, the Salary Checker tool considers taxation, assuming a certain percentage for deductions. This ensures that the net income displayed is a more accurate representation of take-home pay after taxes.
        </p>
        <br />
        <p className='text-xl'>
        Overall, the Salary Checker tool empowers users with insights into their financial situation, allowing them to make informed decisions regarding their budget and expenditures. Its simplicity and functionality make it an indispensable tool for anyone seeking clarity on their income and financial standing.
        </p>
      </div>
    </div>
  );
};

export default SalaryCalculator;
