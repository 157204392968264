import React, { useState } from 'react';
import './SpellingChecker.css'; // Import your stylesheet

const SpellChecker = () => {
  const [content, setContent] = useState('');

  const handleChange = (e) => {
    setContent(e.target.innerHTML);
  };

  return (
    <section>
    <div className="spelling-checker-container">
      <div className='text-3xl font-bold text-center mt-5'>
        <h1>Spelling Checker</h1>
      </div>

      <div
        className='editable-content rounded '
        contentEditable
        onInput={handleChange}
        dangerouslySetInnerHTML={{ __html: content }}
      />

      <div className='text-center mt-5 mb-5'>
        <p className='text-xl font-semibold'>Review your text above for spelling errors.</p>
      </div>

    
    </div>

    <div className='mb-5 ml-2'>
        <h1 className='text-3xl font-bold'>Perfect Your Text with Our Spelling Checker Tool</h1>
        <h2 className='text-2xl font-semibold mt-5'>
        Harness the Power of Precision

        </h2>
        <p className='text-xl mt-2'>
        Introducing our state-of-the-art Spelling Checker Tool, designed to elevate your writing by eliminating pesky spelling mistakes effortlessly. In the fast-paced digital landscape, a single typo can tarnish your message. Our tool ensures that your content is flawless and resonates with professionalism.
        </p>

        <h2 className='text-3xl font-semibold mt-5'>
        Why Choose Our Spelling Checker?

        </h2>

        <h2 className='text-2xl font-semibold mt-5'>
        1. Reliability:

        </h2>
        <p className='text-xl mt-2'>
        Rely on the robust spell-checking capabilities of our tool, leveraging the browser's innate functionality for accurate and dependable results.
        </p>
        <h2 className='text-2xl font-semibold mt-5'>
        2. User-Friendly Interface:


        </h2>
        <p className='text-xl mt-2'>
        Our design prioritizes user experience, making the spell-checking process intuitive and accessible to users of all levels of proficiency.
        </p>
        <p className='text-xl mt-2'>
        Our tool is designed to be efficient and user-friendly, allowing you to focus on your content instead of the technical details.
        </p>
        <h2 className='text-2xl font-semibold mt-5'>
        3. Professionalism:

        </h2>
        <p className='text-xl mt-2'>
        Our tool is designed to be user-friendly, ensuring that you have access to the most up-to-date information and resources.
        </p>
        <h2 className='text-2xl font-semibold mt-5'>
        4. Flexibility:

        </h2>
        <p className='text-xl mt-2'>
        Our tool is designed to be flexible, allowing you to choose from a range of languages and dialects to improve your writing.
        </p>
        <h2 className='text-2xl font-semibold mt-5'>Conclusion
</h2>

<p className='text-xl mt-2'>
In the realm of effective communication, spelling accuracy is non-negotiable. Embrace the future of error-free writing with our Spelling Checker Tool. Perfect your text, boost your credibility, and make every word count. Try it now and experience a new standard in spelling precision.
</p>
    </div>
    </section>
  );
}

export default SpellChecker;
