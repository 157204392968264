"use client";
import React, { useState } from 'react';
import axios from 'axios';
import "./loader.css";

const SEOChecker = () => {
  const [urlInput, setUrlInput] = useState('');
  const [seoData, setSeoData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  


  const extractSEO = async () => {
    if (!urlInput) {
      setError('Please enter a valid URL.');
      return;
    }

    const encodedParams = new URLSearchParams();
    encodedParams.set('url', urlInput);

    const options = {
      method: 'POST',
      url: 'https://canssens-seo-extraction-v1.p.rapidapi.com/seo/api/',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
        'X-RapidAPI-Host': 'canssens-seo-extraction-v1.p.rapidapi.com',
      },
      data: encodedParams,
    };

    try {
      setIsLoading(true); 
      const response = await axios.request(options);
      setSeoData(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setSeoData(null);
      setError('Error during SEO extraction. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      extractSEO();
    }
  };

  return (
    
    <div>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
     
     <section>
      <div className='  w-[100%]   p-5'>
        <h1 className='text-center text-3xl font-semibold'>SEO Checker</h1>
        <div className='flex flex-col text-center'>
          <input
            className='border-[#34495e] text-black border-[2px] w-[90%] sm:w-[55%] ml-[5%] sm:ml-[22%] mt-5 py-1 rounded-lg px-2'
            placeholder='Enter your URL'
            type="text"
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className='mt-4'>
            <button onClick={extractSEO} className='bg-blue-600 text-white px-4 py-2 rounded'>
              Check SEO
            </button>
          </div>
        </div>
      </div>

      <div>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        {seoData && (
          <div className='mt-4 px-6'>
            <div>
              <div class="relative overflow-x-auto rounded-xl">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-black uppercase bg-gray-50 dark:bg-slate-200 dark:text-black">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-center text-xl">
                        SEO Report
                      </th>
                    </tr>
                  </thead>
                  <tbody>
            <tr class="flex flex-col sm:flex-row  bg-white border-b dark:bg-gray-100 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                    URL:
                </th>
                <td class="px-6 py-4 text-black">
                {seoData.url}
                </td>
                
            </tr>
            <tr class="flex flex-col sm:flex-row bg-white border-b dark:bg-gray-100 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   Title:
                </th>
                <td class="px-6 py-4 text-black">
                   {seoData.title}
                </td>
            
            </tr>
            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   Keywords:
                </th>
                <td class="px-6 py-4 text-black">
                    {seoData.keywords}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   Description:
                </th>
                <td class="px-6 py-4 text-black">
                    {seoData.description}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   H1 Tags:
                </th>
                <td class="px-6 py-4 text-black">
                {seoData.h1 && seoData.h1.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   H2 Tags:
                </th>
                <td class="px-6 py-4 text-black">
                {seoData.h2 && seoData.h2.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>

            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   H3 Tags:
                </th>
                <td class="px-6 py-4 text-black">
                {seoData.h3 && seoData.h3.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < seoData.h1.length - 1 && ' , '}
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>


            
            <tr class="flex flex-col sm:flex-row bg-white dark:bg-gray-100">
                <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black">
                   Links:
                </th>
                <td class="px-6 py-4 text-black">
                {seoData.links && seoData.links.map((link, index) => (
            <React.Fragment key={index}>
                {link}
                {index < seoData.links.length - 1 && ','}
                <br />
            </React.Fragment>
        ))}
                </td>
                
                
            </tr>
        </tbody>
    </table>
</div>

        </div>

        </div>
        
      )}
      </div>

      <div className='ml-2 mt-5 mb-5'>
        <p className='text-xl'>
        Introducing our cutting-edge SEO Checker Tool – your go-to solution for optimizing website performance and boosting online visibility. In today's digital landscape, search engine optimization (SEO) plays a pivotal role in determining a website's success. Our SEO Checker Tool empowers you to analyze and enhance crucial aspects of your online presence, ensuring that your website is primed for search engine success.
        </p>
  <br />
        <p className='text-xl'>
        Our tool offers a comprehensive examination of your website's SEO health, providing detailed insights into key factors that impact search engine rankings. From on-page elements like meta tags, headings, and keyword density to off-page elements such as backlinks and domain authority, our SEO Checker Tool meticulously assesses every aspect that influences your website's search engine performance.
        </p>
<br />
        <p className='text-xl'>
        One standout feature of our SEO Checker Tool is its user-friendly interface, making it accessible for both seasoned SEO professionals and beginners alike. Simply enter your website URL, and the tool generates a detailed report highlighting strengths, weaknesses, and opportunities for improvement. It also provides actionable recommendations to address identified issues, ensuring that you can implement SEO best practices effortlessly.
        </p>
<br />
        <p className='text-xl'>
        Stay ahead of the competition with our real-time analysis feature, allowing you to monitor changes in your website's SEO performance over time. Regularly assessing your SEO metrics is crucial for adapting to evolving search engine algorithms and maintaining a competitive edge in the online landscape.


        </p>
<br />
        <p className='text-xl'>
        Whether you're a business owner, marketer, or web developer, our SEO Checker Tool equips you with the insights needed to enhance your website's search engine ranking, attract more organic traffic, and ultimately achieve your online goals. Take control of your website's destiny in the digital realm – try our SEO Checker Tool today and embark on a journey toward SEO excellence!

        </p>
      </div>
      </section>
    </div>
    
  );
};

export default SEOChecker;