import React from 'react'
import { Link } from 'react-router-dom'
import logo from './logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
  return (
    <footer className='bg-[#001f3f] flex flex-col justify-center items-center sm:flex-row py-2 text-white w-full whitespace-nowrap'>
        <div className='sm:w-[20%] flex flex-row'>
          <img className='w-[70px] ml-5' src={logo} alt="" />
     <h2 className='text-2xl font-semibold px-5 py-1 -ml-5'>Online Tools</h2>
     </div>
     <div className='sm:w-[40%] sm:ml-[25%]'>
        <ul className='flex flex-col sm:flex-row gap-3 mt-1 ml-[15%] text-xl whitespace-nowrap'>
          <Link to="/">
            <li>Home</li>
            </Link>
            <Link to="/about">
            <li>About</li>
            </Link>
            <Link to="/contact-us">
            <li>Contact</li>
            </Link>
            <Link to="/privacy-policy">
            <li>Privacy Policy</li>
            </Link>
        </ul>
     </div>
     <div className='flex flex-row mt-5 sm:mt-0 sm:flex-row gap-5 sm:w-[40%] sm:ml-[25%]'>
      <i>
      <FontAwesomeIcon size='2xl' icon={faFacebook} /> </i>
      <i>
      <FontAwesomeIcon size='2xl' icon={faInstagram} /> </i>
      <i>
      <FontAwesomeIcon size='2xl' icon={faTwitter} /> </i>

     </div>
    </footer>
  )
}

export default Footer