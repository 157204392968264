import React from 'react';

const InternetSpeed = () => {
  const iframeStyle = {
    border: 'none',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    minHeight: '360px',
    overflow: 'hidden',
  };

  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <div style={{ minHeight: '360px' }}>
          <div
            style={{
              width: '100%',
              height: '0',
              paddingBottom: '50%',
              position: 'relative',
            }}
          >
            <iframe
              style={iframeStyle}
              src="https://openspeedtest.com/Get-widget.php"
              title="Internet Speed Test"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetSpeed;
