import React, { useState } from 'react';

const ImageCompressor = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const dataUrl = event.target.result;
      setOriginalImage(dataUrl);
      setCompressedImage(null); // Reset compressed image when a new image is uploaded
    };

    reader.readAsDataURL(file);
  };

  const compressImage = () => {
    const img = new Image();
    img.src = originalImage;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const { width, height } = img;
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        setCompressedImage(URL.createObjectURL(blob));
      }, 'image/jpeg', 0.7); // Adjust the quality as needed
    };
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = compressedImage || originalImage;
    link.download = 'compressed_image.jpg';
    link.click();
  };

  return (
    <section>
      <div>
        <h1 className='text-3xl font-bold text-center mt-10'>Image Compressor</h1>
      </div>
    <div className='flex flex-col items-center mt-10 mb-10'>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {originalImage && <img className='w-[400px] mt-5 rounded' src={originalImage} alt="Original" />}
      {compressedImage && <img className='w-[400px] mt-10 rounded' src={compressedImage} alt="Compressed" />}
      {originalImage && !compressedImage && (
        <button onClick={compressImage} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg mt-10'>Compress Image</button>
      )}
      {compressedImage && (
        <button onClick={downloadImage} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg mt-5'>Download Compressed Image</button>
      )}
    </div>

    <div className='ml-5 mb-5'>
      <h2 className='text-3xl font-bold mt-5'>Introduction</h2>
      <p className='text-xl mt-2'>
      Welcome to our Image Compressor Tool! This powerful web application empowers users to effortlessly compress their images, reducing file sizes without compromising quality. Whether you're a web developer optimizing website performance or an enthusiast looking to save storage space, our tool is designed with simplicity and efficiency in mind.
      </p>

      <h2 className='text-2xl font-semibold mt-5'>Uploading Images
</h2>
<p className='text-xl mt-2'>
To begin, users can upload their images easily by clicking on the designated file input button. Supported image formats include JPEG, PNG, GIF, and more. The tool ensures a seamless experience for users who want to compress a single image or multiple images simultaneously.


</p>

<h2 className='text-2xl font-semibold mt-5'>Original Image Preview
</h2>
<p className='text-xl mt-2'>
Upon uploading an image, users can instantly preview the original image. This feature allows them to confirm the selected image and assess its quality before proceeding with the compression process.
</p>

<h2 className='text-2xl font-semibold mt-5'>Quality Adjustment
</h2>
<p className='text-xl mt-2'>
Our Image Compressor Tool provides users with the flexibility to adjust compression quality. A user-friendly slider or input allows users to set the desired compression level, striking a perfect balance between file size reduction and image quality.


</p>
    </div>
    </section>
  );
};

export default ImageCompressor;
