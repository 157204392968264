import React, { useState } from 'react';
import axios from 'axios';
import JSZip from 'jszip';

function InstagramVideoDownloader() {
  const [videoUrls, setVideoUrls] = useState('');
  const [apiResponses, setApiResponses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true); // Set loading to true when the download button is pressed

      const urls = videoUrls.split(',').map(url => url.trim());

      const responses = await Promise.all(
        urls.map(async (url) => {
          return await axios.post('https://all-media-downloader.p.rapidapi.com/rapid_download/download', 
          new URLSearchParams({ url }), {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
              'X-RapidAPI-Host': 'all-media-downloader.p.rapidapi.com'
            }
          });
        })
      );

      console.log('API Responses:', responses.map((response) => response.data));

      setApiResponses(responses.map((response) => response.data));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when the API requests are complete
    }
  };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();

      await Promise.all(
        apiResponses.map(async (apiResponse, index) => {
          if (apiResponse && apiResponse.data && apiResponse.data.url) {
            // Use responseType 'arraybuffer' to get the raw video data
            const videoData = await axios.get(apiResponse.data.url, { responseType: 'arraybuffer' });

            // Create a Blob from the video data
            const videoBlob = new Blob([videoData.data], { type: 'video/mp4' });

            // Add the Blob to the ZIP file
            zip.file(`video_${index + 1}.mp4`, videoBlob);
          }
        })
      );

      // Generate the ZIP file
      const content = await zip.generateAsync({ type: 'blob' });

      // Trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = 'videos.zip';
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  const shouldShowDownloadAllButton = apiResponses.length > 1; // Show button if there's more than one URL

  return (
    <section>
      <div className="App text-white h-screen overflow-auto">

        <div className='text-center'>
          <h1 className='text-3xl'>Instagram Video Downloader</h1>
          <div>
            <input
              className='w-[40%] rounded-lg py-2 px-2 mt-4 text-black border-2 border-[#001f3f]'
              placeholder='Enter one or more URLs with separate commas'
              value={videoUrls}
              onChange={(e) => setVideoUrls(e.target.value)}
            />
          </div>
          <button onClick={handleDownload} className='bg-yellow-600 hover:bg-yellow-700 px-3 py-2 rounded-lg mt-5 text-xl font-semibold'>
            Download Videos
          </button>

          {/* Show loader when loading is true */}
          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
        </div>

        {apiResponses.length > 0 && (
  <div className='mt-5'>
    <hr className='mt-3 mb-5' />
    {apiResponses.map((apiResponse, index) => {
  console.log(`API Response for Video ${index + 1}:`, apiResponse); // Add this line for debugging

  return (
    <div key={index} className='h-[400px] overflow-auto'>
      {apiResponse && apiResponse.data && apiResponse.data.url ? (
        <div className='text-center'>
          <video className='ml-[30%] rounded h-[400px]' controls width="400" height="200">
            <source src={apiResponse.data.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <br />
          <a href={apiResponse.data.url} download={`video_${index + 1}`}>
            <button className='bg-yellow-600 hover:bg-yellow-700 px-2 py-2 text-xl font-semibold rounded'>Download Video {index + 1}</button>
          </a>
        </div>
      ) : (
        <p>No video found in the response for Video {index + 1}.</p>
      )}
    </div>
  );
})}

    <br />
    {shouldShowDownloadAllButton && (
      <button onClick={handleDownloadAll}>Download All Videos (ZIP)</button>
    )}
  </div>
)}

      </div>

    </section>
  );
}

export default InstagramVideoDownloader;
