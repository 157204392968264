import React, { useState } from 'react';
import axios from 'axios';

const DomainAuthority = () => {
  const [domain, setDomain] = useState('');
  const [result, setResult] = useState(null);

  const checkDomainAuthority = async () => {
        try {
          const response = await axios.get('https://domain-da-pa-check.p.rapidapi.com/', {
            params: {
              target: domain
            },
            headers: {
              'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
              'X-RapidAPI-Host': 'domain-da-pa-check.p.rapidapi.com'
            }
          });
      
          console.log('API Response:', response.data); 
          setResult(response.data);
        } catch (error) {
          console.error('Error checking domain authority:', error);
        }
      };
      
  return (
    <div>
      <h2>Domain Authority Checker</h2>
      <label>
        Enter the domain:
        <input
          type="text"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          placeholder="www.example.com"
        />
      </label>
      <br />
      <button onClick={checkDomainAuthority}>Check Domain Authority</button>

      {result && (
        <div>
          <h3>Result</h3>
          <p><strong>Domain:</strong> {result.target}</p>
          <p><strong>Domain Authority:</strong> {result.da_score}</p> {/* Updated line */}
          <p><strong>Page Authority:</strong> {result.pa_score}</p>
          <p><strong>Spam Score:</strong> {result.spam_score}</p>
          <p><strong>Total Backlinks:</strong> {result.total_backlinks}</p>
          {/* Include other relevant information here */}
        </div>
      )}
    </div>
  );
};

export default DomainAuthority;
