import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WhatsMyIp = () => {
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    // Fetch the IP address from the API
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://ipinfo.io/ip');
        setIpAddress(response.data);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Call the function to fetch IP address
    fetchIpAddress();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <section>
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>What's My IP</h1>
      <div className='border-2 w-[200px] ml-[25%] sm:ml-[43%] mt-10 mb-10 rounded'>
      {ipAddress && <p className='text-center text-xl font-semibold mt-5 mb-5'> <span> Your IP address is:</span> {ipAddress}</p>}
      </div>
    </div>
    <div className='mb-10 ml-2'>
        <p className='text-xl mt-5'>
        The IP Checker tool is a valuable utility that provides users with essential information about their internet connection. This tool reveals both the public IPv4 and IPv6 addresses, allowing users to understand the unique identifiers assigned to their devices on the internet. The IP location feature goes beyond simple addresses, offering insight into the physical location of the user's internet connection, including the city, region, and country. This functionality is particularly useful for individuals curious about the geographical representation of their online presence.
        </p>
        <p className='text-xl mt-5'>
        Moreover, the tool displays information about the Internet Service Provider (ISP) responsible for providing the internet connection. Users can quickly identify the organization responsible for their connectivity, enhancing their awareness of the network infrastructure supporting their online activities.

        </p>
        <p className='text-xl mt-5'>
        The user-friendly interface of the IP Checker tool ensures accessibility for individuals of varying technical expertise. Whether users are troubleshooting network issues, verifying their VPN configurations, or simply satisfying their curiosity, this tool provides a comprehensive overview of crucial information related to their internet connection. With its clear presentation and informative details, the IP Checker tool empowers users to understand and manage their online identity effectively.
        </p>
    </div>
    </section>
  );
};

export default WhatsMyIp;
