import React, { useState } from 'react';

const GrammarChecker = () => {
  const [text, setText] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const checkGrammar = async () => {
    try {
      const response = await fetch('https://languagetool.org/api/v2/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `text=${encodeURIComponent(text)}&language=en-US`,
      });

      const data = await response.json();

      if (data.matches) {
        const grammarSuggestions = data.matches.map(match => ({
          offset: match.offset,
          length: match.length,
          suggestions: match.replacements.map(replacement => replacement.value),
        }));
        setSuggestions(grammarSuggestions);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error checking grammar:', error);
    }
  };

  const renderSuggestions = (suggestions) => {
    return suggestions.map((suggestion, index) => (
      <span key={index} className="suggestion">
        {suggestion}
        {index < suggestions.length - 1 && ', '}
      </span>
    ));
  };

  return (
    <div>
      <div className='text-3xl font-bold text-center mt-5 '>
        <p>Free Grammer Checker</p>
      </div>
        <div className='mt-5  sm:ml-[37%]'>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter your text here..."
        className='border-[2px] w-[300px] sm:w-[500px] sm:ml-0 ml-5 px-2 rounded border-[#001f3f]'
        rows={5}
        cols={50}
      />
      </div>
      <div className='text-center mt-5 mb-5'>
      <button onClick={checkGrammar} className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg'>Check Grammar</button>
      </div>
      {suggestions.length > 0 && (
        <div>
          <h2>Grammar Suggestions:</h2>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                <strong>Offset:</strong> {suggestion.offset}, <strong>Length:</strong> {suggestion.length}, <strong>Suggestions:</strong> {renderSuggestions(suggestion.suggestions)}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className='ml-2 mb-5'>
        <h1 className='text-3xl font-bold'>
Elevate Your Writing with Our Grammar Checker Tool</h1>
<h2 className='mt-5 text-2xl font-semibold
'>
   Unleash the Power of Flawless Texts


</h2>

<p className='mt-2 text-xl'>
In the dynamic world of digital content, ensuring grammatical correctness is paramount. Introducing our advanced Grammar Checker Tool, a game-changer in refining your writing and perfecting your prose. Say farewell to embarrassing typos, spelling errors, and grammar mishaps, and embrace a new era of polished and professional writing.

</p>

<h2 className='mt-5 text-2xl font-semibold'>
Precision with Every Keystroke

</h2>

<p className='text-xl mt-2'>
Our tool employs sophisticated algorithms to meticulously analyze your text, providing precise suggestions for grammar, punctuation, and spelling improvements. Whether you're a student, professional, or content creator, our Grammar Checker ensures that your message is conveyed with utmost clarity.
</p>

<h2 className='text-2xl font-semibold mt-5'>
User-Friendly Interface for Effortless Editing
</h2>

<p className='text-xl mt-2'>
Designed with simplicity in mind, our tool features an intuitive interface that makes proofreading a breeze. Copy and paste your text, click a button, and witness as our tool highlights potential errors and offers insightful suggestions. Editing your content has never been this easy.
</p>

<h2 className='text-2xl font-semibold mt-5'>
Boost SEO with Error-Free Content

</h2>
<p className='text-xl mt-2'>
Search engines prioritize high-quality, error-free content. By using our Grammar Checker, you not only enhance the readability of your text but also improve its search engine optimization (SEO) ranking. Present your audience with content that not only informs but captivates.
</p>
      </div>
    </div>
  );
};

export default GrammarChecker;
