import React, { useState } from 'react';
import './EssayChecker.css'; // Import your stylesheet

const EssayChecker = () => {
  const [essay, setEssay] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const checkEssay = async () => {
    try {
      const response = await fetch('https://api.languagetool.org/v2/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `text=${encodeURIComponent(essay)}&language=en-US&enabledOnly=false`,
      });

      const data = await response.json();

      if (data.matches) {
        setSuggestions(data.matches);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error checking essay:', error);
    }
  };

  return (
    <section>
    <div className="essay-checker-container">
      <div className='text-3xl font-bold text-center mt-5'>
        <h1>Essay Checker</h1>
      </div>

      <div>
        <textarea
          className='border-2 sm:ml-[5%] w-[95%] ml-2 sm:w-[600px] rounded mt-5 px-2 border-[#001f3f]'
          placeholder='Enter your essay'
          value={essay}
          onChange={(e) => setEssay(e.target.value)}
          rows="10"
          cols="80"
        />
      </div>

      <div className='text-center mt-5 mb-5'>
        <button
          className='text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg'
          onClick={checkEssay}
        >
          Check Essay
        </button>
      </div>

      {suggestions.length > 0 && (
        <div className='suggestions-container'>
          <h2 className='text-xl font-semibold text-center'>Grammar and Style Suggestions:</h2>
          <ul>
            {suggestions.map((match, index) => (
              <li key={index} className='text-xl mb-10'>
                Message: {match.message}, Offset: {match.offset}, Length: {match.length}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
    <div className='ml-2 mb-5'>
        <h2 className='text-3xl font-bold mt-5'>
        Elevate Your Writing with Our Essay Checker Tool

        </h2>

        <h2 className='text-2xl font-semibold mt-5'>
        Unleashing the Power of Perfection

        </h2>
        <p className='text-xl mt-2'>
        Welcome to the Essay Checker Tool—an indispensable ally for writers striving for impeccable essays. In the dynamic realm of digital composition, a well-crafted essay is a testament to your skills, and our tool is here to ensure every word resonates with precision.
        </p>
        <h2 className='text-2xl font-semibold'>
        Seamlessness in Essay Enhancement

        </h2>
        <h2 className='text-2xl font-semibold mt-5'>
        Input with Ease: 
        </h2>
        <p className='text-xl mt-2 '>
        The tool features a user-friendly textarea where you can effortlessly enter or paste your essay for evaluation.
        </p>

        <h2 className='text-2xl font-semibold mt-5'>
        Swift Analysis: 
        </h2>
        <p className='text-xl mt-2 '>
        A click of the "Check Essay" button triggers a behind-the-scenes analysis, leveraging advanced language processing to pinpoint potential grammar and style issues.
        </p>
        <h2 className='text-2xl font-semibold mt-5 '>
        Insightful Feedback:
        </h2>
        <p className='text-xl mt-2'>
        The results are displayed in a structured manner, offering insights into areas of improvement, be it in grammar, style, or overall coherence.
        </p>

        <h2 className='text-2xl font-semibold mt-5'>
        Conclusion

        </h2>
        <p className='text-xl mt-2'>
        In the ever-evolving landscape of written expression, our Essay Checker Tool stands as a beacon of precision. Perfect your prose, refine your style, and embark on a journey towards flawless essays. Try it now and witness the transformation of your writing into a masterpiece of linguistic artistry.
        </p>
    </div>
    </section>
  );
}

export default EssayChecker;
