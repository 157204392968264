import React, { useState } from 'react';

const PngToJpgConverter = () => {
  const [file, setFile] = useState(null);
  const [convertedBlob, setConvertedBlob] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const convertToJpg = () => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            setConvertedBlob(blob);
          }, 'image/jpeg');
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    if (convertedBlob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(convertedBlob);
      link.download = 'converted_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <section>
    <div className='flex flex-col justify-center items-center gap-5 mt-2'>
      <h1 className='text-3xl font-bold'>PNG to JPG Converter</h1>
      <input type="file" accept=".png" onChange={handleFileChange} />
      <button onClick={convertToJpg} className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg'>Convert to JPG</button>

      {convertedBlob && (
        <div className='flex flex-col justify-center  items-center gap-5'>
         
          <img className='w-[400px] rounded-lg' src={URL.createObjectURL(convertedBlob)} alt="Converted" style={{ maxWidth: '100%' }} />
          <button onClick={handleDownload} className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg'>Download Converted Image</button>
        </div>
      )}
    </div>
    <div className='ml-2 mt-5 mb-5'>
        <p className='text-xl'>
        Introducing our state-of-the-art PNG to JPG Converter tool, a versatile solution tailored to meet your image format conversion requirements effortlessly. Whether you're a creative professional, an amateur photographer, or someone seeking to optimize their visual content, our tool provides a straightforward method for converting PNG images to the widely supported JPG format. The best part? No reliance on external APIs or intricate software installations; it's all about simplicity and convenience.
        </p>
        <br />
        <p className='text-xl'>
        Navigating the tool is a breeze—simply upload your PNG file, click the "Convert to JPG" button, and witness the instantaneous transformation. Utilizing client-side processing, our tool ensures a swift and smooth conversion experience directly within your browser, eliminating the need for time-consuming uploads or complicated procedures.
        </p>
        <br />
        <p className='text-xl'>
        Once the conversion is complete, preview the JPG image on-screen with our intuitive interface. We understand the importance of user-friendly features, and that's why we've incorporated a "Download Converted Image" button, enabling you to promptly save your newly converted JPG file to your device.
        </p>
        <br />
        <p className='text-xl'>
        Bid farewell to the complexities of seeking external conversion services or grappling with intricate software. Our PNG to JPG Converter puts control firmly in your hands, providing a quick and reliable solution for all your image format conversion needs. Embrace the efficiency and user-friendly design of our tool to streamline your image processing tasks effortlessly. Try it today and experience a seamless transition between PNG and JPG formats with unmatched ease and speed.
        </p>
    </div>
    </section>
  );
};

export default PngToJpgConverter;
