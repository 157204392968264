import React, { useState } from "react";

const TexttoImage = () => {
  const [inputText, setInputText] = useState("");
  const [generatedImage, setGeneratedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGenerateImage = () => {
    setLoading(true); // Show loader
    setGeneratedImage(null); // Clear previous image
    setErrorMessage(null); // Clear error message

    fetch(
      "https://api-inference.huggingface.co/models/runwayml/stable-diffusion-v1-5",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({ inputs: inputText }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.blob();
      })
      .then((blob) => {
        setGeneratedImage(window.URL.createObjectURL(blob));
      })
      .catch((error) => {
        console.error("Error generating image:", error);
        setErrorMessage("Error generating image. Please try again later.");
      })
      .finally(() => {
        setLoading(false); // Hide loader after image is generated or an error occurs
      });
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGenerateImage();
    }
  };

  const handleDownloadImage = () => {
    if (generatedImage) {
      const link = document.createElement("a");
      const imageName = inputText.trim() || "generated_image";
      link.href = generatedImage;
      link.download = `${imageName}.png`;
      link.click();
    }
  };

  return (
    
    <div className="container">
      <h1 id="title" className="text-3xl font-bold text-center mt-2">
        Image <span className="span">Generator</span>
      </h1>
      <div className="form-container flex justify-center mt-2">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleInputKeyPress}
          placeholder="Write your prompt here"
          className="input-style border-2 w-[300px] rounded-lg px-2 py-1"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center mt-5 mb-5">
      <button onClick={handleGenerateImage} className="button bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg text-center">
        Generate Image
      </button>
      </div>

      <div className="image-container">
        {errorMessage && <p className="error-message">{errorMessage}</p>}
       
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
            &nbsp;          
          {loading && <p className="loading-text">AI is Generating Image</p>}
          </div>
        ) : (
          generatedImage && (
            <div>
              <div className="flex flex-col justify-center items-center text-center">
              <img className="rounded-lg w-[400px]" src={generatedImage} alt="Generated" />
              </div>
              <div className="text-center mt-5 mb-5">
              <button onClick={handleDownloadImage} className="download-btn text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg">
                Download Image
              </button>
              </div>
            </div>
          )
        )}
      </div>
      <div className="ml-2 mb-5 mt-5">
        <p className="text-xl">
          
Introducing our cutting-edge Text to Image Converter, a powerful AI-driven tool designed to revolutionize your creative projects. As a beginner web developer with a burgeoning interest in AI and web applications, this tool is tailored to elevate your digital endeavors.
        </p>
        <br />
        <p className="text-xl">
        Our Text to Image Converter harnesses the capabilities of advanced artificial intelligence to seamlessly transform your textual ideas into captivating visuals. Whether you're crafting content for your React applications or enhancing your web development projects, this tool acts as your indispensable companion in the realm of image generation.
        </p>
        <br />
        <p className="text-xl">
        Powered by state-of-the-art algorithms, our tool interprets your input text and generates visually stunning images with precision and flair. The synergy of HTML, CSS, JavaScript, and React can be seamlessly integrated to showcase dynamic and engaging results on your web applications. As you navigate the intricacies of React app development, this tool becomes an asset in enriching user experiences through the incorporation of dynamic visuals generated on-the-fly.
        </p>
        <br />
        <p className="text-xl">
        This user-friendly tool empowers you to effortlessly experiment with various textual inputs, exploring the endless possibilities of image creation. From enhancing the aesthetic appeal of your websites to adding a dynamic touch to your React applications, our Text to Image Converter is your gateway to a more advanced and visually immersive web development journey.
        </p>
      </div>
    </div>
  );
};

export default TexttoImage;