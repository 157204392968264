import React, { useState } from 'react';

const TimeCalculator = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [result, setResult] = useState('');

  const calculateTime = () => {
    const totalMinutes = hours * 60 + minutes;
    setResult(`Total time: ${hours} hours and ${minutes} minutes is ${totalMinutes} minutes.`);
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-2 mb-2'>Time Calculator</h1>
      <div className='flex flex-col items-center gap-4'>
        <div className='flex gap-2'>
          <input
            type='number'
            placeholder='Enter hours'
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            className='border-[3px] rounded-lg px-2 py-1'
          />
          <span className='text-xl font-semibold'>hours</span>
        </div>
        <div className='flex gap-2'>
          <input
            type='number'
            placeholder='Enter minutes'
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            className='border-[3px] rounded-lg px-2 py-1'
          />
          <span className='text-xl font-semibold'>minutes</span>
        </div>
        <button onClick={calculateTime} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg'>
          Calculate
        </button>
      </div>
      {result && <p className='text-green-500 font-bold mt-4 text-xl text-center mb-5'>{result}</p>}
      <div className='ml-2 mt-10 mb-10'>
        <p className='text-xl'>
        The Time Calculator tool is a handy utility designed to streamline time-related calculations, enabling users to efficiently manage and comprehend durations. This React-based application simplifies the process of adding or subtracting hours and minutes, providing a user-friendly interface for quick and accurate results.
        </p>
        <br />
        <p className='text-xl'>
        The tool consists of input fields for hours and minutes, empowering users to input their desired time values with ease. Upon entering the time components, the "Calculate" button triggers the computation, converting the user-input hours and minutes into the total equivalent in minutes. The result is then displayed prominently on the screen, offering a clear and concise representation of the calculated time.
        </p>
        <br />
        <p className='text-xl'>
        This tool proves particularly useful in scenarios where users need to aggregate or deduce time durations, such as in project management, scheduling, or time tracking. The straightforward design enhances user experience, making the Time Calculator an efficient and accessible solution for anyone dealing with time-related calculations. Whether managing work hours, planning events, or tracking time intervals, this tool provides a valuable resource for individuals seeking a seamless and accurate approach to time calculations in their daily activities.
        </p>
      </div>
    </div>
  );
};

export default TimeCalculator;
