import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';

const PDFCompressor = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const compressPDF = async () => {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);

      // Perform PDF compression or other manipulations as needed

      const compressedPdfBytes = await pdfDoc.save();
      const compressedPdfBlob = new Blob([compressedPdfBytes], { type: 'application/pdf' });

      // Download the compressed PDF
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(compressedPdfBlob);
      downloadLink.download = 'compressed.pdf';
      downloadLink.click();
    } catch (error) {
      console.error('Error compressing PDF:', error);
    }
  };

  return (
    <div className='mt-5'>
      <h1 className='text-3xl font-bold text-center'>PDF Compressor</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <input className='mt-2 mb-2' type='file' onChange={handleFileChange} accept='.pdf' />
        <button onClick={compressPDF} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg'>
          Compress PDF
        </button>
      </div>
      <div>
        <p className='text-xl mt-10 mb-10 ml-2'>
            
Introducing the PDF Compressor Tool, a user-friendly solution designed to reduce the size of your PDF files effortlessly. This handy web application enables users to upload their PDF documents and efficiently compress them, minimizing file size while maintaining document quality. The tool is built with simplicity in mind, allowing users to navigate the compression process with ease.
        </p>
        <br />
        <p className='text-xl'>
        Upon selecting a PDF file, the tool employs advanced techniques to compress the document, ensuring a reduction in file size by up to 40%. Users can then download the compressed PDF, providing a more manageable and shareable version of their documents.
        </p>
        <br />
        <p className='text-xl'>
        The PDF Compressor Tool utilizes the powerful PDF-lib library, which enables seamless manipulation of PDF documents. The compression process is performed securely on the client side, ensuring user privacy and data integrity. Whether you need to optimize PDFs for storage, email attachments, or faster online sharing, this tool streamlines the process, making PDF compression accessible to users of all technical levels.
        </p>
        <br />
        <p className='text-xl'>
        Experience the convenience of reducing PDF file sizes effortlessly with the PDF Compressor Tool, empowering users to manage and share documents more efficiently in their digital workflows.
        </p>
      </div>
    </div>
  );
};

export default PDFCompressor;
