import React, { useState } from 'react';

const VoltageConverter = () => {
  const [volts, setVolts] = useState('');
  const [kilovolts, setKilovolts] = useState('');

  const convertToKilovolts = () => {
    if (volts !== '' && !isNaN(volts)) {
      const voltsValue = parseFloat(volts);
      const kilovoltsValue = voltsValue / 1000;
      setKilovolts(kilovoltsValue.toFixed(4));
    } else {
      // Handle invalid input
      setKilovolts('');
    }
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Voltage Converter</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <div className='flex flex-col gap-2'>
       <p className='text-xl font-semibold mt-5 mb-2'>
          Square Meters:
          </p>
          <input
            type='number'
            className='border-2 rounded-lg px-2 w-[300px]'
           placeholder='Enter Voltage (Volts)'
          value={volts}
          onChange={(e) => setVolts(e.target.value)}
          />
      
        </div>
        <button
          onClick={convertToKilovolts}
          className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg mt-2'
        >
          Convert to Square Kilometers
        </button>
        {kilovolts !== '' && <p className='text-xl font-semibold'>{`${volts} Volts is equal to ${kilovolts} Kilovolts.`}</p>}
      </div>
      <div className='mt-10 ml-2 mb-20'>
        <p className='text-xl'>

        The Voltage Converter tool is designed to simplify the process of converting electrical voltage measurements between volts and kilovolts. This handy utility proves especially beneficial for individuals working in electrical engineering, electronics, or anyone dealing with voltage-related calculations.        </p>
        <br />
        <p className='text-xl'>
        With the Voltage Converter tool, users can effortlessly input a value in volts, typically denoted by the symbol "V," and obtain its equivalent in kilovolts (kV). This conversion is crucial as kilovolts represent a unit of electrical potential or voltage equal to one thousand volts. The tool streamlines this conversion process, eliminating the need for manual calculations.


        </p>
        <br />
        <p className='text-xl'>
        Whether you're a student, engineer, or hobbyist, the Voltage Converter tool ensures accuracy and efficiency in voltage-related tasks. By providing a user-friendly interface, the tool allows individuals to focus on their projects without the hassle of complex calculations. As technology and electronics continue to play a significant role in various industries, having access to tools like the Voltage Converter simplifies workflows and contributes to the overall ease of working with electrical measurements.        </p>
      </div>
    </div>
  );
};

export default VoltageConverter;
