import React from 'react'

const TexttoVoice = () => {
    function convert() {
        var text = document.getElementById("text").value;
        var url = "https://api.voicerss.org/?key=700246c6f89f4e3d8e9804ea4f285e78&hl=en-us&src=" + encodeURIComponent(text) + "&f=48khz_16bit_stereo";
      
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            var url = URL.createObjectURL(blob);
            var audio = document.getElementById("audio");
            audio.src = url;
            audio.play();
          })
          .catch(error => console.error(error));
      }
  return (
    <section>
        <script src="https://cdn.voicerss.org/1.0.0/libraries/voicerss.min.js"></script>
        <div>
            <h1 className='text-3xl font-bold text-center mt-2'>Text to Voice</h1>
        </div>
        <div className='text-center mt-5'>
            <textarea name=""  className='border-[3px] border-[#001f3f] w-[95%] sm:w-[600px] rounded px-2' placeholder='Enter your text' id="text" cols="70" rows="10"></textarea>
        </div>
        <div className='text-center mt-5 mb-5'>
            <button onClick={convert} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Convert to Voice</button>
        </div>
        <div className='mt-5 text-center flex justify-center mb-5'>
            <audio id='audio' controls></audio>
        </div>
        <div className='ml-2 mb-5'>
            <p className='text-xl'>
            The Text-to-Voice Converter tool revolutionizes communication by transforming written content into spoken words, catering to individuals seeking an auditory experience. This innovative tool serves a myriad of purposes, from aiding visually impaired users in accessing digital content to enhancing the overall accessibility of information for diverse audiences.
            </p>
            <br />
            <p className='text-xl'>
            With an intuitive interface, users can effortlessly convert written text into natural-sounding speech, choosing from a variety of voices and accents to personalize their experience. The tool employs advanced text-to-speech technology, ensuring clear articulation and accurate pronunciation of words, making it suitable for educational, entertainment, or professional applications.
            </p>
            <br />
            <p className='text-xl'>
            Whether used for learning, accessibility, or creative expression, the Text-to-Voice Converter is a versatile and user-friendly solution that bridges the gap between written information and spoken communication, opening up new possibilities for a more inclusive and interactive digital landscape.
            </p>
        </div>
    </section>
  )
}

export default TexttoVoice;