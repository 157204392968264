import React, { useState } from 'react';

const MetaTagGenerator = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [generatedMetaTags, setGeneratedMetaTags] = useState('');

  const generateMetaTags = () => {
    const metaTags = [];

    if (title) {
      metaTags.push(`<meta name="title" content="${title}" />`);
    }

    if (description) {
      metaTags.push(`<meta name="description" content="${description}" />`);
    }

    if (keywords) {
      metaTags.push(`<meta name="keywords" content="${keywords}" />`);
    }

    setGeneratedMetaTags(metaTags.join('\n'));
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Meta Tag Generator</h1>
      <div className='flex flex-col  items-center text-center justify-center'>
        <p className='text-xl font-semibold mt-10 mb-2'>
          Title:
        </p>
        <div>
          <input
            className='border-[3px] rounded-lg px-2 py-1 w-[250px]'
            placeholder='Enter your title'
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <br />
      <div className='flex flex-col text-center justify-center'>
        <p className='text-xl font-semibold mb-2'>
          Description:
        </p>
        <div>
          <input
            className='border-[3px] px-2 py-1 rounded w-[250px]'
            placeholder='Enter your description'
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <br />
      <div className='flex flex-col text-center justify-center'>
        <p className='text-xl font-semibold mb-2'>
          Keywords:
        </p>
        <div>
          <input
            className='border-[3px] px-2 py-1 rounded w-[250px]'
            placeholder='Enter your keywords'
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </div>
      </div>
      <br />
      <div className='text-center mb-10'>
        <button
          className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg'
          onClick={generateMetaTags}
        >
          Generate Meta Tags
        </button>
      </div>
      <div className='text-xl text-center'>
        {generatedMetaTags ? (
          <div className='text-center ml-[10%] mt-5 mb-5'>
            <pre className='w-[80%] bg-blue-200 rounded-lg overflow-hidden whitespace-pre-wrap p-4'>
              {generatedMetaTags}
            </pre>
          </div>
        ) : (
          <p></p>
        )}
      </div>
      <div className='ml-2 mt-5 mb-5'>
        <p className='text-xl'>
          
The Meta Tag Generator tool is an essential utility for web developers and digital marketers, streamlining the process of creating meta tags for HTML documents. Meta tags play a crucial role in providing information about a webpage to search engines and users. This user-friendly tool simplifies the generation of meta tags, requiring no advanced HTML knowledge.
        </p>
        <br />
        <p className='text-xl'>
        With the Meta Tag Generator, users can effortlessly input the title, description, and keywords of their web page, and the tool dynamically generates the corresponding meta tags in the recommended format. The generated meta tags adhere to best practices, ensuring optimal visibility in search engine results.
        </p>
        <br />
        <p className='text-xl'>
        This tool is particularly valuable for improving search engine optimization (SEO) efforts, enhancing the discoverability of web content. Users can quickly experiment with different titles, descriptions, and keywords, fine-tuning their meta tags to align with their content and target audience.
        </p>
        <br />
        <p className='text-xl'>
        Whether for a personal blog, business website, or e-commerce platform, the Meta Tag Generator contributes to a more efficient and effective web development process. Its simplicity empowers both beginners and experienced developers to create well-structured meta tags, ultimately contributing to improved search engine rankings and better user engagement.
        </p>
      </div>
    </div>
  );
};

export default MetaTagGenerator;
