import React from 'react'

const PasswordGenerator = () => {
    function generatePassword() {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
        let password = "";
    
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset.charAt(randomIndex);
        }
    
        const passwordInput = document.getElementById("password");
        passwordInput.value = password;
        passwordInput.select();
    }
    
    function copyToClipboard() {
        const passwordInput = document.getElementById("password");
        passwordInput.select();
        document.execCommand("copy");
        alert("Password copied to clipboard!");
    }
    
  return (
   <section className='mt-5 mb-5'>
    <div>
        <h1 className='text-3xl font-bold text-center'>Password Generator</h1>
    </div>

    <div className='flex flex-col justify-center items-center text-center gap-3 mt-5 mb-5'>
        <div>
        <input className='border-2 w-[300px] rounded-lg px-2' placeholder='Your generated password here' id='password' readOnly type="text" />
        </div>

        <div>
            <button onClick={generatePassword} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg '>Generate Password</button>
        </div>
    </div>
    <div className='flex justify-center mb-10'>
        <button onClick={copyToClipboard} className='bg-blue-600 hover:bg-blue-700 text-white px-2 py-1 rounded-lg'>Copy Password</button>
    </div>

     <div className='ml-2'>
        <p className='text-xl'>
        The Password Generator tool is a robust and secure solution designed to assist users in creating strong and unique passwords for enhanced online security. In an era where cyber threats are prevalent, having a reliable password is crucial to safeguarding sensitive information across various online platforms.
        </p>
        <br />
        <p className='text-xl'>
        This user-friendly tool allows individuals to customize passwords based on specific requirements. Users can define the length of the password, include uppercase and lowercase letters, numbers, and special characters. The Password Generator ensures the creation of complex and unpredictable passwords that are resistant to hacking attempts, enhancing overall digital security.
        </p>
        <br />
        <p className='text-xl'>
        The tool's versatility is particularly valuable for users managing multiple accounts across various websites and applications. By generating unique passwords for each account, individuals minimize the risk of unauthorized access and potential security breaches. Additionally, the Password Generator promotes good password hygiene by encouraging the use of intricate combinations that are challenging for cyber attackers to decipher.


        </p>
        <br />
        <p className='text-xl'>
        Whether for personal use or within a professional setting, the Password Generator serves as a proactive measure against common security vulnerabilities. Its intuitive interface and customizable features empower users to effortlessly generate strong passwords, contributing to a safer and more secure online experience.
        </p>
     </div>
   </section>
  )
}

export default PasswordGenerator