import { Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import NavBar from './components/NavBar';
import PlagiarismChecker from './components/PlagiarismChecker';
import ArticleRewriter from './components/ArticleRewriter';
import GrammerChecker from './components/GrammerChecker';
import WordCounter from './components/WordCounter';
import SpellChecker from './components/SpellChecker';
import EssayChecker from './components/EssayChecker';
import MD5Generator from './components/MD5Generator';
import UppercaseToLowercaseConverter from './components/Uppercasetolower';
import WordCombiner from './components/WordCombiner';
import TexttoImage from './components/Text-to-Image';
import SpeechtoText from './components/SpeechtoText';
import ResumeMaker from './components/ResumeMaker';
import ImageCompressor from './components/ImageCompressor';
import ScreenResolution from './components/ScreenResolution';
import InternetSpeed from './components/InternetSpeed';
import RobotsTxt from './components/RobotsTxt';
import SSLChecker from './components/SSLChecker';
import WhatsMyIp from './components/WhatsMyIP';
import DomainAuthority from './components/DomainAuthority';
import MetaTagGenerator from './components/MetaTag';
import PasswordGenerator from './components/PasswordGenerator';
import ImagetoPDF from './components/ImagetoPDF';
import PDFCompressor from './components/CompressPDF';
import SpeedConverter from './components/SpeedConverter';
import AreaConverter from './components/AreaConverter';
import VoltageConverter from './components/VoltageConverter';
import PercentageCalculator from './components/PercentageCalculator';
import SalaryCalculator from './components/SalaryCalculator';
import PaypalFeeCalculator from './components/PaypalFeeCalculator';
import InstagramVideoDownloader from './components/InstagramVideoDownloader';
import TikTokDownloader from './components/TikTokDownloader';
import TimeCalculator from './components/TimeCalculator';
import RGBtoHEX from './components/RGBtoHEX'
import SEOChecker from './components/SEOChecker'
import PDFtoImage from './components/PDFtoImage';
import TexttoVoice from './components/TexttoVoice';
import QRCodeMaker from './components/QRCodeMaker';
import SideBar from './components/SideBar';
import FacebookVideoDownloader from './components/FacebookVideoDownloader';
import BackgroundRemover from './components/BackgroundRemover';
import About from './components/About';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import JPGtoPNGConverter from './components/JPGtoPNG';
import PngToJpgConverter from './components/PNGtoJPG';

function App() {



  return (
    <Router>
      <div className="App overflow-hidden">
        <NavBar />
        <div className='flex flex-col sm:flex-row'>
        <div className='sm:w-[90%]'>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/plagiarism-checker" element={<PlagiarismChecker />} />
          <Route path="/article-rewriter" element={<ArticleRewriter />} />
          <Route path="/grammer-checker" element={<GrammerChecker />} />
          <Route path="/word-counter" element={<WordCounter />} />
          <Route path="/spelling-checker" element={<SpellChecker />} />
          <Route path="/essay-checker" element={<EssayChecker />} />
          <Route path="/md5-generator" element={<MD5Generator />} />
          <Route path="/uppercase-to-lowercase-converter" element={<UppercaseToLowercaseConverter />} />
          <Route path="/word-combiner" element={<WordCombiner />} />
          <Route path="/text-to-image" element={<TexttoImage />} />
          <Route path="/speech-to-text" element={<SpeechtoText />} />
          <Route path="/resume-maker" element={<ResumeMaker />} />
          <Route path="/image-compressor" element={<ImageCompressor />} />
          <Route path="/rgb-to-hex" element={<RGBtoHEX />} />
          <Route path="/seo-checker" element={<SEOChecker />} />
          <Route path="/screen-resolution-checker" element={<ScreenResolution />} />
          <Route path="/internet-speed-checker" element={<InternetSpeed />} />
          <Route path="/robots-txt-generator" element={<RobotsTxt />} />
          <Route path="/ssl-checker" element={<SSLChecker />} />
          <Route path="/what-is-my-ip" element={<WhatsMyIp />} />
          <Route path="/domain-authority-checker" element={<DomainAuthority />} />
          <Route path="/meta-tag-generator" element={<MetaTagGenerator />} />
          <Route path="/password-generator" element={<PasswordGenerator />} />
          <Route path="/image-to-pdf" element={<ImagetoPDF />} />
          <Route path="/compress-pdf" element={<PDFCompressor />} />
          <Route path="/speed-converter" element={<SpeedConverter />} />
          <Route path="/area-converter" element={<AreaConverter />} />
          <Route path="/voltage-converter" element={<VoltageConverter />} />
          <Route path="/percentage-calculator" element={<PercentageCalculator />} />
          <Route path="/salary-calculator" element={<SalaryCalculator />} />
          <Route path="/paypal-fee-calculator" element={<PaypalFeeCalculator />} />
          <Route path="/instagram-video-downloader" element={<InstagramVideoDownloader />} />
          <Route path="/tiktok-downloader" element={<TikTokDownloader />} />
          <Route path="/time-calculator" element={<TimeCalculator />} />
          <Route path="/text-to-image" element={<TexttoImage />} />
          <Route path="/pdf-to-image" element={<PDFtoImage />} />
          <Route path="/text-to-voice" element={<TexttoVoice />} />
          <Route path="/qr-code-maker" element={<QRCodeMaker />} />
          <Route path="/facebook-video-downloader" element={<FacebookVideoDownloader />} />
          <Route path="/image-background-remover" element={<BackgroundRemover />} />
          <Route path="/privacy-policy-generator" element={<BackgroundRemover />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/jpg-to-png" element={<JPGtoPNGConverter />} />
          <Route path="/png-to-jpg" element={<PngToJpgConverter />} />
        
        </Routes>
        </div>
        <div>
          <Routes>
        <Route exact path='/' element={<>
            <SideBar />
          </>} />
          </Routes>
        </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
