import React, { useRef } from 'react';
import jsPDF from 'jspdf'; // Import the jsPDF library

const ImagetoPDF = () => {
  const showImgRef = useRef(null);
  const newImgRef = useRef(null);

  const loadImg = (event) => {
    showImgRef.current.src = URL.createObjectURL(event.target.files[0]);

    newImgRef.current = document.createElement('img');
    newImgRef.current.src = URL.createObjectURL(event.target.files[0]);

    showImgRef.current.onload = () => {
      URL.revokeObjectURL(showImgRef.current.src);
    };
  };

  const pdfDown = () => {
    console.log(newImgRef.current);
    const doc = new jsPDF(); // Use the imported jsPDF library
    doc.addImage(newImgRef.current, 10, 10);
    doc.save('imgToPdf.pdf');
  };

  return (
    <div>
      <div className="flex flex-col justify-center text-center items-center gap-4">
        <h1 className="text-3xl font-bold text-center mt-5">Image to PDF Convert</h1>
        <div className="upload-img">
          <input
            type="file"
            name=""
            onChange={(event) => loadImg(event)}
            accept=".png, .jpg, .jpeg"
          />
        </div>
        <div>
          <img
            className="w-[400px] rounded-lg"
            ref={showImgRef}
            alt=""
          />
          <button
            onClick={() => pdfDown()}
            className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg mt-5 mb-5"
          >
            Download PDF
          </button>
        </div>
      </div>
      <div className='ml-2 mb-20 mt-5'>
        <p className='text-xl'>
            
The Image to PDF Converter Tool is a versatile utility designed to simplify the process of converting images into PDF files. This web application allows users to effortlessly convert JPEG, JPG, and PNG images into a single, downloadable PDF document.
        </p>
        <br />
        <p className='text-xl'>
        The tool features a user-friendly interface where users can upload their desired images through a simple file input. Once an image is selected, the tool dynamically displays it for preview. Users can easily navigate and view the image within the application.
        </p>
        br
        <p className='text-xl'>
        To enhance functionality, the tool utilizes the popular jsPDF library, enabling seamless conversion of images to PDF format. Upon selecting and previewing the image, users can initiate the conversion process with a click of a button labeled "Download PDF." The application then generates a PDF file incorporating the chosen image.
        </p>
        <br />
        <p className='text-xl'>
        This Image to PDF Converter Tool is particularly useful for individuals who need a quick and efficient way to compile multiple images into a single, easily shareable PDF document. Whether for personal or professional use, this tool streamlines the conversion process, making it accessible to users with varying technical expertise.
        </p>
      </div>
    </div>
  );
};

export default ImagetoPDF;
