import React from 'react'
import { Link } from 'react-router-dom';

const SideBar = () => {
  return (
    <section className='flex flex-col w-[500px] sm:w-full sm:mt-20 ml-2 sm:ml-0'>
      <div className='border-2 h-[200px] sm:-ml-[130%] mr-2 mt-10 rounded-lg'>
       <div className='bg-pink-600 text-center text-white text-2xl font-semibold rounded-lg py-1'>
        Popular Tools
       </div>
        <div>
          <ul className='text-xl ml-2 font-semibold flex flex-col gap-y-3 text-center'>
            <Link to="/text-to-image">
            <li>Text to Image</li>
            </Link>
            <Link to="/seo-checker">
            <li>SEO Checker</li>
            </Link>
            <Link to="/article-rewriter">
            <li>Article Rewriter</li>
            </Link>
            <Link to="/resume-maker">
            <li>Resume Maker</li>
            </Link>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default SideBar;