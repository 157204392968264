import React, { useState } from 'react';

const WordCombiner = () => {
  const [firstWord, setFirstWord] = useState('');
  const [secondWord, setSecondWord] = useState('');
  const [thirdWord, setThirdWord] = useState('');
  const [combinedResults, setCombinedResults] = useState([]);

  const combineWords = () => {
    const results = [
      `${firstWord}${secondWord}${thirdWord}`,
      `${secondWord}${thirdWord}${firstWord}`,
      `${thirdWord}${firstWord}${secondWord}`,
      `${firstWord}${thirdWord}${secondWord}`,
      `${secondWord}${firstWord}${thirdWord}`,
      `${thirdWord}${secondWord}${firstWord}`,
    ];
    setCombinedResults(results.filter(result => result.trim() !== ''));
  };

  return (
    <section>
    <div className="word-combiner-container">
      <div className="text-3xl font-bold text-center mt-5">
        <h1>Word Combiner</h1>
      </div>
  <div className='flex flex-col justify-center items-center'>
      <div>
        <input
          className="border-2 rounded mt-5 px-2 sm:w-[300px]"
          placeholder="Enter the first word"
          type="text"
          value={firstWord}
          onChange={(e) => setFirstWord(e.target.value)}
        />
      </div>

      <div>
        <input
          className="border-2 rounded mt-5  px-2 sm:w-[300px]"
          placeholder="Enter the second word"
          type="text"
          value={secondWord}
          onChange={(e) => setSecondWord(e.target.value)}
        />
      </div>

      <div>
        <input
          className="border-2 rounded mt-5  px-2 sm:w-[300px]"
          placeholder="Enter the third word"
          type="text"
          value={thirdWord}
          onChange={(e) => setThirdWord(e.target.value)}
        />
      </div>
      </div>

      <div className="text-center mt-5 mb-5">
        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg"
          onClick={combineWords}
        >
          Combine Words
        </button>
      </div>

      {combinedResults.length > 0 && (
        <div className="result-container text-center items-center flex flex-col mb-10 ">
          <h2 className="text-xl font-semibold">Combined Results:</h2>
          <div className="result-details text-xl sm:w-[400px]">
            {combinedResults.map((result, index) => (
              <p key={index}>{result}</p>
            ))}
          </div>
        </div>
      )}
    </div>
    <div className='ml-2 mb-5 mt-5'>
        <p className='text-xl'>
        The Word Combiner tool is a versatile utility designed to effortlessly generate various combinations of words entered by the user. Developed using React, this user-friendly application simplifies the process of creating permutations, considering different orders of the input words.
        </p>

        <p className='text-xl mt-5'>
        The tool incorporates three input fields, allowing users to enter distinct words. Upon clicking the "Combine Words" button, the application dynamically produces an array of combinations, showcasing all possible permutations of the entered words. The generated results include combinations like "word1word2word3," "word2word3word1," and so forth. The tool ensures that whitespace is appropriately managed, producing accurate and readable combinations.
        </p>

        <p className='text-xl mt-5'>
        With its clean and intuitive design, the Word Combiner tool caters to a wide range of users, from those seeking creative word arrangements to those requiring diverse test cases. The React-based implementation ensures efficiency and responsiveness, contributing to a seamless user experience.
        </p>

        <p className='text-xl mt-5'>
        Whether for linguistic exploration or practical applications in software testing, this tool offers a straightforward solution for generating comprehensive word combinations with just a few clicks. Its flexibility and simplicity make it a valuable addition to any web-based environment, demonstrating the power of React in developing practical and interactive tools.
        </p>
    </div>
    </section>
  );
};

export default WordCombiner;
