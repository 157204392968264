import React, { useState } from 'react';
import md5 from 'md5';
import './MD5Generator.css'; // Import your stylesheet

const MD5Generator = () => {
  const [inputString, setInputString] = useState('');
  const [md5Hash, setMD5Hash] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const generateMD5Hash = () => {
    const hash = md5(inputString);
    setMD5Hash(hash);
    setIsCopied(false); // Reset copied state when generating a new hash
  };

  const copyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = md5Hash;

    // Append the textarea to the DOM
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand('copy');

    // Remove the textarea from the DOM
    document.body.removeChild(textArea);

    setIsCopied(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <section>
    <div className="md5-generator-container">
      <div className='text-3xl font-bold text-center mt-5'>
        <h1>MD5 Hash Generator</h1>
      </div>

      <div>
        <textarea
          className='border-[2px] rounded mt-5 sm:ml-[10%] px-2 border-[#001f3f] w-[95%] ml-2 sm:w-[600px]'
          name=""
          id=""
          cols="60"
          rows="10"
          placeholder="Enter your string"
          value={inputString}
          onChange={(e) => setInputString(e.target.value)}
        ></textarea>
      </div>

      <div className='text-center mt-5 mb-5'>
        <button
          className='text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg'
          onClick={generateMD5Hash}
        >
          Generate MD5 Hash
        </button>

        {/* Copy to clipboard button */}
        {md5Hash && (
          <button
            className={`ml-3 text-white ${isCopied ? 'bg-green-500' : 'bg-blue-500'} hover:bg-blue-600 px-4 py-1 rounded-lg`}
            onClick={copyToClipboard}
          >
            {isCopied ? '✔ Copied' : 'Copy to Clipboard'}
          </button>
        )}

        {/* Refresh page button */}
        <button
          className='ml-3 mt-2 sm:mt-0 text-white bg-red-500 hover:bg-red-600 px-4 py-1 rounded-lg'
          onClick={refreshPage}
        >
          Refresh Page
        </button>
      </div>

      {md5Hash && (
        <div className='md5-result-container  w-[70%] flex flex-col justify-center items-center '>
          <h2 className='text-xl font-semibold'>Result:</h2>
          <div className='result-details text-xl'>
            <p>Your String: <span className='font-bold'> {inputString}</span> </p>
            <p className='mt-2'>MD5 Hash: <span className='font-bold'> {md5Hash} </span></p>
            {/* Add other hash functions if needed */}
          </div>
        </div>
      )}
    </div>
    <div className='ml-2'>
        <h2 className='text-3xl font-bold mt-5'>What is MD5?</h2>
        <p className='text-xl mt-2'>
        MD5, or Message Digest Algorithm 5, is a widely used cryptographic hash function that produces a 128-bit (16-byte) hash value. Developed by Ronald Rivest in 1991, MD5 is designed to take an input message and produce a fixed-size output hash, typically represented as a 32-character hexadecimal number.
        </p>

        <p className='text-xl mt-2 mb-5'>
        While MD5 was initially popular for its speed and efficiency in generating unique hash values, it is now considered insecure for cryptographic purposes due to vulnerabilities that allow for collision attacks—where different inputs produce the same hash. As a result, MD5 has been replaced by more secure hash functions in critical security applications. However, it still finds utility in non-cryptographic applications such as checksums and checksum verifications, where its speed remains an advantage. Despite its limitations, MD5 played a significant role in the history of cryptography and digital security.
        </p>
    </div>
    </section>
  );
};

export default MD5Generator;
