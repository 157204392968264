import React from 'react';
import './SpeechtoText.css';

const SpeechtoText = () => {
  return (
    <section>
      {/* <div className="etool-title">
        <link href="https://docs.google.com/uc?export=download&amp;id=1ZVFfFPy_Ry6S0kUo5U8NzoaHqW2MWRVr" rel="stylesheet" type="text/css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"></link>
        <h3>Voice To Text Generating Free Online Tool</h3>
      </div>
      <div className="etool-wrapper" style={{ border: '0px solid rgb(255, 0, 0)' }}>
        <div id="info" style={{ color: 'red', fontSize: '18px', padding: '3px 6px' }}>
          <div id="info_start">Click on the microphone icon and start speaking.</div>
          <div id="info_speak_now" style={{ display: 'none' }}>Speak now || Hindi/Urdu !</div>
          <div id="info_no_speech" style={{ display: 'none' }}>No speech was detected. Adjust your microphone settings.</div>
          <div id="info_no_microphone" style={{ display: 'none' }}>No microphone.! Ensure that a microphone configured correctly.</div>
          <div id="info_allow" style={{ display: 'none' }}>Click the "Allow" button above to enable your microphone.</div>
          <div id="info_denied" style={{ display: 'none' }}>Permission to use microphone was denied.</div>
          <div id="info_blocked" style={{ display: 'none' }}>Permission to use microphone is blocked. To change, go to chrome://settings/contentExceptions#media-stream</div>
          <div id="info_upgrade" style={{ display: 'none' }}>This facility is not supported by your browser. Upgrade to <a href="https://www.google.com/chrome" target="_blank">Chrome</a>.</div>
        </div>
        <div className="flex-box">
          <select id="select_language" onChange={() => updateCountry()} style={{ margin: '1px 0px', padding: '0px' }}></select>
          <select id="select_dialect" style={{ margin: '1px 0px', padding: '0px' }}></select>
          <button id="start_button" onClick={(event) => startButton(event)} style={{ background: 'rgb(255, 255, 255)', padding: '0px' }} title="Click and Start Speaking">
            <img alt="Start" height="30" id="start_img" src="https://www.google.com/intl/en/chrome/assets/common/images/content/mic.gif" width="64" />
          </button>
        </div>
        <div id="results" style={{ background: 'rgb(255, 255, 255)', border: '2px dotted gray', margin: '5px', minHeight: '180px', padding: '5px', textAlign: 'left' }}>
          <span className="final" id="final_span" style={{ color: 'green', paddingRight: '3px' }}></span> <span className="interim" id="interim_span" style={{ color: 'red' }}></span>
        </div>
        <div className="etool-button-wrapper flex-box">
          <button className="etool-button" id="button1" onClick={() => ToClipboard('results')} title="Copy Text"><i aria-hidden="true" className="fa fa-files-o"></i> Copy</button>
          <button className="etool-button" id="email_button" onClick={() => emailButton()} title="Send through Email"><i aria-hidden="true" className="fa fa-envelope"></i> Send Email</button>
          <button className="etool-button" name="download" onClick={() => saveTextAsUnicode()} title="Download Text"><i aria-hidden="true" className="fa fa-download"></i> Download Text</button>
        </div>
        <script src="https://dl.dropbox.com/s/07a7qmtn09z2luo/Voice-Typing_API.js"></script>
      </div> */}
    </section>
  );
};

export default SpeechtoText;
