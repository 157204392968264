import React, { useState} from 'react';
import axios from 'axios';

const PlagiarismChecker = () => {
  const [inputText, setInputText] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async () => {
    const options = {
      method: 'POST',
      url: 'https://plagiarism-checker-and-auto-citation-generator-multi-lingual.p.rapidapi.com/plagiarism',
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
        'X-RapidAPI-Host': 'plagiarism-checker-and-auto-citation-generator-multi-lingual.p.rapidapi.com'
      },
      data: {
        text: inputText,
        language: 'en',
        includeCitations: false,
        scrapeSources: false
      }
    };

    try {
      setLoading(true); // Show loader
      const response = await axios.request(options);
      setResult(response.data);
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false); // Show loader

    }
  };

  return (
    
    <div className='mt-5'>
      
      <div>
      <div className='flex flex-col text-center'>
        <h1 className='text-3xl font-semibold text-center'>Plagiarism Checker</h1>
        <div>
 
<textarea  onChange={(e) => setInputText(e.target.value)} name="" placeholder='Enter your Article'   className=' border-[2px] w-[95%] ml-2 sm:w-[60%] sm:ml-[10%] mt-5 px-2 py-1 rounded border-[#001f3f]' id="inputText" cols="30" rows="10"></textarea>

</div>

      </div>
      <div className='text-center mt-5'>
        <button onClick={handleSubmit} className='bg-blue-500 hover:bg-blue-600 px-3 py-2 text-white rounded-lg'>Submit</button>
      </div>
      </div>
      <div className='mt-5'>
        <div className='flex flex-col justify-center items-center'> 
      {loading && (
          <div className="loader-container">
            <div className="loader"></div>
            &nbsp;
            {loading }
          </div>
        )}
        </div>
        {result && (
          <div className='ml-2'>
            <p className='text-xl font-semibold'>Percent Plagiarism: {result.percentPlagiarism}%</p>
            {result.sources.map((source, index) => (
              <div key={index} className='mt-3'>
                <h3 className='text-xl font-semibold'>{source.title}</h3>
                <p><span className='text-xl font-semibold'>URL:</span>  <a href={source.url} target='_blank' rel='noopener noreferrer'>{source.url}</a></p>
                {source.matches.map((match, matchIndex) => (
                  <div key={matchIndex} className='mt-2'>
                    <p> <span className='text-xl font-semibold'>Match Text:</span> {match.matchText}</p>
                    <p className='font-semibold'>Score: {match.score}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      
      <section className='mt-5 mb-5 ml-2'>
        <h1 className='text-2xl font-bold'>The Essence of Plagiarism Checker Tools in Modern Content Creation</h1>
        <p className='mt-2 text-xl'>
        Plagiarism Checker Tools have become indispensable in the realm of content creation, serving as vigilant guardians against the unethical practice of content duplication. In a digital landscape where originality is paramount, these tools play a pivotal role in upholding integrity and fostering a culture of creativity. Let's delve into the significance and functionality of these tools, shedding light on how they contribute to maintaining the authenticity of online content.
        </p>

        <h2 className='text-2xl font-semibold mt-4'>
        Understanding Plagiarism Checker Tools
        </h2>
        <h3 className='text-xl font-semibold mt-5'>Ensuring Originality:
</h3>

<p className='text-xl mt-2'>
Plagiarism Checker Tools are designed to meticulously scan digital content and identify instances of copied or duplicated material. Whether you are a student submitting an academic paper or a web developer crafting unique website content, these tools act as a safeguard, ensuring that your work stands out for its authenticity.
</p>

<h3 className='text-xl font-semibold mt-5'>
How Plagiarism Checkers Work:

</h3>

<p className='text-xl mt-2'>
Utilizing advanced algorithms, these tools compare the submitted content against an extensive database, flagging similarities and providing a percentage of originality. The comprehensive scans include text, images, and even code snippets, making them an invaluable asset for developers like you who are dedicated to creating unique and innovative web applications.

</p>

<h3 className='text-xl font-semibold mt-5'>
SEO Benefits:

</h3>

<p className='text-xl mt-2'>
Apart from ensuring ethical content creation, plagiarism-free material positively impacts search engine optimization (SEO). Search engines prioritize original and high-quality content, boosting the visibility of your website in search results. By incorporating a plagiarism checker into your web development process, you not only maintain the integrity of your work but also enhance its online presence.


</p>

<p className='text-xl mt-5'>
In conclusion, as you embark on your journey as a web developer, integrating a plagiarism checker into your toolkit will be instrumental in creating a digital footprint that reflects your commitment to originality. These tools not only help in upholding ethical standards but also contribute to the overall success of your web development projects in the vast and competitive online landscape.

</p>
      </section>
      
    </div>
  );
};

export default PlagiarismChecker;
