import React, { useState } from 'react';

const RobotsTxt = () => {
  const [userAgent, setUserAgent] = useState('*');
  const [allowDisallow, setAllowDisallow] = useState('');
  const [generatedRobotsTxt, setGeneratedRobotsTxt] = useState('');

  const handleUserAgentChange = (e) => {
    setUserAgent(e.target.value);
  };

  const handleAllowDisallowChange = (e) => {
    setAllowDisallow(e.target.value);
  };

  const generateRobotsTxt = () => {
    const generatedContent = `User-agent: ${userAgent}\n${allowDisallow}\n`;
    setGeneratedRobotsTxt(generatedContent);
  };

  return (
    <section>
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Robots.txt Generator</h1>
      <div className='text-center'>
      <label className='text-xl'>
        User-agent:
        <div>
        <input className='border-2 rounded mt-2'  type="text" value={userAgent} onChange={handleUserAgentChange} />
        </div>
      </label>
      </div>
      <br />
      <div className='text-center'>
      <label className='text-xl'>
        Allow/Disallow:
        <div>
        <input className='border-2 rounded mt-2 px-2 py-1' placeholder='Type Allow or Disallow' type="text" value={allowDisallow} onChange={handleAllowDisallowChange} />
        </div>
      </label>
      </div>
      <br />
      <div className='text-center mb-5'>
      <button onClick={generateRobotsTxt} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Generate Robots.txt</button>
      </div>

      {generatedRobotsTxt && (
        <div className='text-xl text-center'>
          <h3>Generated Robots.txt</h3>
          <pre className='font-bold mb-5 mt-2'>{generatedRobotsTxt}</pre>
        </div>
      )}
    </div>

    <div className='ml-2'>
        <p className='text-xl mt-5'>
            
The Robots.txt Generator Tool is a valuable resource for webmasters and developers seeking to control how search engines index their websites. With this user-friendly React app, users can effortlessly create and customize their robots.txt file, fine-tuning directives for different User-agents and specifying allowances or restrictions on specific parts of their site. The intuitive interface allows users to input their desired User-agent and tailor Allow/Disallow rules, providing granular control over search engine crawling behavior.
        </p>

        <p className='text-xl mt-5 mb-5'>
        This tool simplifies the process of generating a robots.txt file by dynamically updating the preview based on user input. The generated robots.txt content is displayed in real-time, enabling users to review and adjust directives before implementing them on their websites. Whether it's permitting access to specific areas or restricting indexing on certain pages, the Robots.txt Generator Tool empowers users to optimize their site's visibility on search engines efficiently. Its straightforward design and immediate feedback make it an essential asset for website administrators looking to enhance their SEO strategy and streamline the management of search engine crawlers.
        </p>
    </div>
    </section>
  );
};

export default RobotsTxt;
