import React from 'react';

const About = () => {
  return (
    <div>
      <h1 className='text-2xl font-bold text-center mt-10'>About Us!</h1>
      <h3 style={{ textAlign: 'center' }} className='text-xl mt-5 mb-5'>
        Welcome To <span id="W_Name1">Online Tools</span>
      </h3>
      <p className='text-xl text-center'>
        <span id="W_Name2">Online Tools</span> is a Professional{' '}
        <span id="W_Type1">Online Tools</span> Platform. Here we will provide
        you only interesting content, which you will like very much. We're
        dedicated to providing you the best of <span id="W_Type2">Online Tools</span>,
        with a focus on dependability and <span id="W_Spec">Online Tools</span>.
        We're working to turn our passion for <span id="W_Type3">Online Tools</span> into
        a booming{' '}
        <a
          href="https://www.blogearns.com/2021/05/free-about-us-page-generator.html"
          rel="do-follow"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          online website
        </a>
        . We hope you enjoy our <span id="W_Type4">Online Tools</span> as much as we
        enjoy offering them to you.
      </p>
      <p className='text-xl text-center mb-10'>
        I will keep posting more important posts on my Website for all of you.
        Please give your support and love.
      </p>
      <p style={{ fontWeight: 'bold', textAlign: 'center' }} className='mb-[17%]'>
        Thanks For Visiting Our Site
        <br />
        <br />
        <span  style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
          Have a nice day!
        </span>
      </p>
    </div>
  );
};

export default About;
