import React, { useState } from 'react';

const PercentageCalculator = () => {
  const [total, setTotal] = useState('');
  const [percentage, setPercentage] = useState('');
  const [result, setResult] = useState(null);

  const calculatePercentage = () => {
    const totalValue = parseFloat(total);
    const percentageValue = parseFloat(percentage);

    if (!isNaN(totalValue) && !isNaN(percentageValue)) {
      const calculatedPercentage = (percentageValue / 100) * totalValue;
      setResult(calculatedPercentage);
    } else {
      setResult(null);
    }
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Percentage Calculator</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <input
          type='number'
          className='w-[300px] border-2 rounded-lg px-2 mt-5'
          placeholder='Enter total value'
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />
        <input
          type='number'
          className='w-[300px] border-2 rounded-lg px-2'
          placeholder='Enter percentage'
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
        />
        <button
          onClick={calculatePercentage}
          className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg'
        >
          Calculate Percentage
        </button>
        {result !== null && (
          <p className='text-xl font-semibold mt-4'>
            Result: {result.toFixed(2)} (percentage of {total})
          </p>
        )}
      </div>
      <div className='ml-2 mt-10 mb-10'>
        <p className='text-xl'>
            
The Percentage Calculator is a versatile tool designed to simplify the process of calculating percentages within a React application. This user-friendly application empowers individuals to effortlessly determine the percentage of a given value relative to a total.
        </p>
        <br />
        <p className='text-xl'>
        The interface features two input fields—one for the total value and another for the percentage. Users simply input these values, and upon clicking the "Calculate Percentage" button, the application processes the information. The algorithm within the application accurately computes the percentage and promptly displays the result.
        </p>
        <br />
        <p className='text-xl'>
        This tool proves valuable in various scenarios, such as calculating discounts, assessing test scores, or determining portions of a whole. Its intuitive design ensures accessibility for users of all levels of mathematical proficiency, making it an ideal solution for students, professionals, and anyone requiring quick and precise percentage calculations.


        </p>
        <br />
        <p className='text-xl'>

        </p>
      </div>
    </div>
  );
};

export default PercentageCalculator;
