import React from 'react';

const Contact = () => {
  return (
    <section className='mb-[18%]'>
      <h1 className='text-2xl text-center font-bold mt-5 mb-5'>Contact Us!</h1>
      <h2 style={{ textAlign: 'center' }} className='text-xl'>
        Welcome to <span id="W_Name"> Online Tools</span>!
      </h2>

      <p style={{ fontSize: '17px' }} className='text-xl text-center'>
        Please email us if you have any queries about the site, advertising, or anything else.
      </p>

      <div style={{ textAlign: 'center' }} className='flex flex-col items-center'>
        <img
          alt="contact-us"
          height="87"
          src="https://lh3.googleusercontent.com/-BA7qy8h_v1g/YLVCWDNZdCI/AAAAAAAAALw/rsHNJWX0BK4P5CuB0ymG8QkJ9A9E8KchgCLcBGAsYHQ/w320-h87/email-us-1805514__480.webp"
          width="320"
        />

        <p style={{ marginLeft: '25%' }}>
          <i className="fas fa-envelope-open-text" style={{ color: '#2c3e50', fontSize: '20px' }}></i>{' '}
          <b>
            <i> <span id="W_Email"><a href="mailto:admin@trytoit.com">admin@trytoit.com</a></span></i>
          </b>
          <br />

          <i className="fab fa-whatsapp-square" style={{ color: '#3edc81', fontSize: '20px' }}></i>{' '}
          <b><span id="W_whatsapp"><a href="tel:"></a></span></b>
          <br />
        </p>

        <h3 style={{ color: '#3e005d' }}>We will revert to you as soon as possible...!</h3>
        <p style={{ color: '#3e005d', textAlign: 'center' }}>
          Thank you for contacting us! <br />
          <b>Have a great day</b>
        </p>
        <span style={{ fontSize: '1px', opacity: '0' }}>
          This page is generated with the help of{' '}
          <a href="https://www.blogearns.com/2021/06/free-contact-us-page-generator.html" style={{ color: 'inherit' }}>
            Contact Us Page Generator
          </a>
        </span>
      </div>
    </section>
  );
};

export default Contact;
