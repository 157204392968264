import React, { useState } from 'react';
import screen from './screen.png'

const ScreenResolution = () => {
  const [resolution, setResolution] = useState('');

  const checkResolution = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const resolutionString = `${screenWidth} x ${screenHeight}`;
    setResolution(resolutionString);
  };

  return (
    <section>
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Screen Resolution Checker</h1>
      <div className=' flex justify-center relative'>
        <img src={screen} alt="screen" />
      </div>
      <div className='text-center mt-10'>
      <button onClick={checkResolution} className='text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 rounded-lg mb-20'>Check Resolution</button>
      </div>
      {resolution && (
        <div className='absolute text-center -mt-[115%] sm:-mt-[30%] ml-[18%] sm:ml-[37%] text-xl'>
          <h3>Current Screen Resolution</h3>
          <p className='font-semibold'>{resolution}</p>
        </div>
      )}
    </div>
    </section>
  );
};

export default ScreenResolution;
