import React, { useState } from 'react';

const SpeedConverter = () => {
  const [metersPerSecond, setMetersPerSecond] = useState('');
  const [kilometersPerHour, setKilometersPerHour] = useState('');

  const convertToKilometersPerHour = () => {
    const mps = parseFloat(metersPerSecond);
    if (!isNaN(mps)) {
      const kph = mps * 3.6;
      setKilometersPerHour(kph.toFixed(2));
    } else {
      setKilometersPerHour('');
    }
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Speed Converter</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <input
          type='number'
          className='border-2 w-[300px] rounded-lg px-2 mt-5 mb-5'
          placeholder='Enter speed in meters per second'
          value={metersPerSecond}
          onChange={(e) => setMetersPerSecond(e.target.value)}
        />
        <button
          onClick={convertToKilometersPerHour}
          className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg'
        >
          Convert
        </button>
        {kilometersPerHour !== '' && (
          <p className='text-xl font-semibold mt-4 mb-5'>
            Speed in kilometers per hour: {kilometersPerHour} km/h
          </p>
        )}
      </div>
      <div className='ml-2 mb-20 mt-10'>
        <p className='text-xl'>
        The Speed Converter tool is a user-friendly React application designed to effortlessly convert speed measurements from meters per second to kilometers per hour. This tool caters to users who need a quick and reliable way to perform this common conversion.
        </p>
        <br />
        <p className='text-xl'>
        The interface is simple and intuitive, featuring input fields for the speed in meters per second. Users can input their speed value, and with a click of a button, the tool performs the conversion, providing the equivalent speed in kilometers per hour. The converted result is displayed promptly below the conversion button.


        </p>
        <br />
        <p className='text-xl'>
        This tool is not only practical for everyday users but also serves as a valuable resource for anyone dealing with speed-related calculations in fields such as physics, engineering, or sports. Its clean design and straightforward functionality make it accessible for individuals with varying levels of technical expertise.
        </p>
        <br />
        <p className='text-xl'>
        Whether you're a student, professional, or simply curious about speed conversions, the Speed Converter tool streamlines the process, eliminating the need for manual calculations and ensuring accurate and efficient results.
        </p>
      </div>
    </div>
  );
};

export default SpeedConverter;
