import React, { useState } from 'react';

const AreaConverter = () => {
  const [squareMeters, setSquareMeters] = useState('');
  const [squareKilometers, setSquareKilometers] = useState('');

  const convertToSquareKilometers = () => {
    const sqMeters = parseFloat(squareMeters);
    if (!isNaN(sqMeters)) {
      const sqKilometers = sqMeters / 1e6; // 1 square kilometer = 1e6 square meters
      setSquareKilometers(sqKilometers.toFixed(6));
    } else {
      setSquareKilometers('');
    }
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>Area Converter</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <div className='flex flex-col gap-2'>
       <p className='text-xl font-semibold mt-5 mb-2'>
          Square Meters:
          </p>
          <input
            type='number'
            placeholder='Enter your value'
            className='border-2 rounded-lg px-2 w-[300px]'
            value={squareMeters}
            onChange={(e) => setSquareMeters(e.target.value)}
          />
      
        </div>
        <button
          onClick={convertToSquareKilometers}
          className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg mt-2'
        >
          Convert to Square Kilometers
        </button>
        {squareKilometers !== '' && (
          <p className='text-xl font-semibold mb-5'>
            Square Kilometers: <strong>{squareKilometers}</strong>
          </p>
        )}
      </div>
      <div className='mt-10 ml-2 mb-20'>
        <p className='text-xl'>
        The Area Converter tool is designed to simplify the process of converting measurements between square meters and square kilometers. Whether you're dealing with land dimensions, construction projects, or any application requiring area calculations, this tool provides a quick and efficient solution.
        </p>
        <br />
        <p className='text-xl'>
        With a user-friendly interface, the tool prompts users to input a value in square meters. Upon clicking the conversion button, the application processes the input and displays the equivalent area in square kilometers. The conversion is based on the straightforward conversion factor that 1 square kilometer is equal to 1 million square meters (1 km² = 1,000,000 m²).

        </p>
        <br />
        <p className='text-xl'>
        This tool is beneficial for individuals working in fields such as architecture, urban planning, or real estate, where accurate area measurements are crucial. It eliminates the need for manual calculations, reducing the chances of errors and streamlining the workflow. The clear presentation of results enhances user experience, making it an essential asset for professionals and enthusiasts dealing with area measurements in various contexts. Overall, the Area Converter tool offers convenience, accuracy, and efficiency for anyone dealing with area-related calculations in their projects and activities.
        </p>
      </div>
    </div>
  );
};

export default AreaConverter;
