import React, { useState } from 'react';

const PaypalFeeCalculator = () => {
  const [amount, setAmount] = useState('');
  const [fee, setFee] = useState(0);

  const calculateFee = () => {
    const amountFloat = parseFloat(amount);
    if (!isNaN(amountFloat)) {
      const percentageFee = (amountFloat * 0.0349).toFixed(2);
      const totalFee = parseFloat(percentageFee) + 0.49;
      setFee(totalFee.toFixed(2));
    } else {
      setFee(0);
    }
  };

  return (
    <div>
      <h1 className='text-3xl font-bold text-center mt-5'>PayPal Fee Calculator</h1>
      <div className='flex flex-col items-center text-center justify-center gap-4'>
        <div className='flex flex-col gap-2'>
            <p className='text-xl'>
          Enter Amount:
          </p>
          <div>
          <input
          className='w-[300px] border-2 rounded-lg px-2 py-1'
            type='number'
            step='0.01'
            placeholder='Enter amount'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          </div>
        </div>
        <button onClick={calculateFee} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-lg'>
          Calculate Fee
        </button>
        {fee > 0 && (
          <p className='text-xl'>
           When you will send <span className='text-green-800 font-bold'>$ {amount}</span>, the amount that will go to fees is <span className='text-red-800 font-bold'>$ {fee}</span>, and recipient will receive this amount <span className='text-blue-800 font-bold'>$ {amount - fee}</span>. The total fee is <span className='text-red-800 font-bold'>{((fee / amount) * 100).toFixed(2)}%</span>.
          </p>
        )}
      </div>
      <div className='ml-2 mt-10 mb-10'>
        <p className='text-xl'>
            
The PayPal Fee Calculator is a handy tool designed to assist users in determining the fees associated with sending money through PayPal. It simplifies the often complex fee structure of PayPal transactions by providing a straightforward calculation based on the entered amount. This user-friendly React app enables individuals to input the desired amount they plan to send, and with a simple click, it instantly computes the fees involved in the transaction.
        </p>
        <br />
        <p className='text-xl'>
        The interface is intuitive, featuring an input field for the amount and a responsive button to trigger the fee calculation. The calculated results are dynamically displayed, highlighting key information in distinct colors for clarity. The sending amount is presented in a vibrant green, the fees in a noticeable red, and the recipient's received amount in a striking blue. Additionally, the percentage of fees relative to the sending amount is emphasized, offering users a comprehensive understanding of the transaction's financial implications.
        </p>
        <br />
        <p className='text-xl'>
        With its clean design and precise calculations, the PayPal Fee Calculator is an invaluable tool for individuals who want to anticipate and comprehend the financial aspects of their PayPal transactions efficiently. Whether for personal or business use, this tool provides users with transparency and empowers them to make informed decisions regarding their financial transactions through the PayPal platform.
        </p>
      </div>
    </div>
  );
};

export default PaypalFeeCalculator;
