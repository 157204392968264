import React, { useState } from 'react';

const RGBtoHEX = () => {
  const [rgbValues, setRGBValues] = useState({ r: 0, g: 0, b: 0 });
  const [hexColor, setHexColor] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRGBValues((prevRGBValues) => ({
      ...prevRGBValues,
      [name]: Math.min(255, Math.max(0, parseInt(value, 10))) || 0,
    }));
  };

  const convertRGBtoHEX = () => {
    const { r, g, b } = rgbValues;
    const hex = `#${(((1 << 24) | (r << 16) | (g << 8) | b) >>> 0).toString(16).slice(1)}`;
    setHexColor(hex);
  };
  

  return (
    <div className='flex flex-col justify-center items-center'>
      <h1 className='text-3xl font-bold mt-5 mb-5'>RGB to HEX Converter</h1>
      
      <div className='flex flex-col sm:flex-row gap-2'>
      <div className='flex'>
       <span className='text-xl font-semibold'> Red:</span>
        <input
        className='border-2 border-black ml-2 rounded'
          type="number"
          name="r"
          value={rgbValues.r}
          onChange={handleInputChange}
          min="0"
          max="255"
        />
      </div>
      <br />
      <div className='flex'>
       <span className='text-xl font-semibold'> Green:</span>
        <input
        className='border-2 border-black ml-2 rounded'
          type="number"
          name="g"
          value={rgbValues.g}
          onChange={handleInputChange}
          min="0"
          max="255"
        />
      </div>
      <br />
      <div className='flex'>
       <span className='text-xl font-semibold'> Blue:</span>
        <input
        className='border-2 border-black ml-2 rounded'
          type="number"
          name="b"
          value={rgbValues.b}
          onChange={handleInputChange}
          min="0"
          max="255"
        />
      </div>
      </div>
      <br />
      <button onClick={convertRGBtoHEX} className='bg-blue-500 text-white hover:bg-blue-600 px-2 py-2 rounded-lg'>Convert to HEX</button>
      <br />
      <div className='text-xl font-semibold mb-5'>
      {hexColor && <div>HEX Color: {hexColor}</div>}
      </div>
      <div className='ml-2 mt-5 mb-5'>
        <p className='text-xl'>
        Introducing our RGB to Hex Converter, a indispensable tool for a budding web developer like yourself, navigating the intricacies of HTML, CSS, JavaScript, and React. As you work on React app development and delve deeper into the world of web development, this tool proves to be an essential asset, simplifying color representation and enhancing your projects.
        </p>
        <br />
        <p className='text-xl'>
        Our RGB to Hex Converter is a user-friendly solution designed to seamlessly translate RGB color values into their corresponding hexadecimal codes. This is particularly beneficial as you tailor the color schemes of your web applications, ensuring consistency and precision in your design choices.
        </p>
        <br />
        <p className='text-xl'>
        The tool's intuitive interface allows you to input RGB values effortlessly and instantly obtain the corresponding hex code. This feature becomes instrumental when customizing styles in your React applications or fine-tuning the aesthetics of your websites. As a beginner web developer, you'll appreciate the simplicity and efficiency our converter brings to your color management workflow.
        </p>
        <br />
        <p className='text-xl'>
        The integration of this tool aligns seamlessly with your skill set, providing a practical solution for translating color codes within the frameworks you're already familiar with. Whether you're adjusting the color palette of a webpage or dynamically styling components in your React projects, the RGB to Hex Converter empowers you to make informed and precise design choices.


        </p>
      </div>
    </div>
  );
};

export default RGBtoHEX;
