import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from './logo.png'

const NavBar = () => {
  return (
    <nav className='bg-[#001f3f] flex flex-row text-white py-2 w-full'>
     <div className='px-[25%] sm:px-0 whitespace-nowrap flex flex-row'>
      <img className='w-[70px] ml-5' src={logo} alt="" />
        <h1 className='text-2xl font-semibold px-5 py-1 -ml-5'>Online Tools</h1>
     </div>

     <div className='ml-[30%] mt-1 text-xl hidden sm:block'>
        <ul className='flex flex-row gap-4'>
          <Link to="/">
            <li>Home</li>
            </Link>
            <Link to="/about">
            <li>About</li>
            </Link>
            <Link to="/contact-us">
            <li>Contact</li>
            </Link>
            <Link to="/privacy-policy">
            <li>Privacy Policy</li>
            </Link>
        </ul>
     </div>
     <div>
     <FontAwesomeIcon icon="fa-solid fa-bars" />
     </div>
    </nav>
  )
}

export default NavBar