import React, { useState } from 'react';

const UppercaseToLowercaseConverter = () => {
  const [inputText, setInputText] = useState('');
  const [convertedText, setConvertedText] = useState('');

  const convertToLowerCase = () => {
    const converted = inputText.toLowerCase();
    setConvertedText(converted);
  };

  return (
    <section>
    <div className="converter-container">
      <div className="text-3xl font-bold text-center mt-5">
        <h1>Uppercase to Lowercase Converter</h1>
      </div>

      <div className='flex text-center justify-center'>
        <textarea
          className="border-[2px] rounded mt-5 px-2 w-[95%] sm:w-[600px]"
          cols={50}
          rows={10}
          placeholder="Enter uppercase text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        ></textarea>
      </div>

      <div className="text-center mt-5 mb-5">
        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg"
          onClick={convertToLowerCase}
        >
          Convert to Lowercase
        </button>
      </div>

      {convertedText && (
        <div className="result-container flex flex-col justify-center text-center items-center">
          <h2 className="text-xl font-semibold">Converted Text:</h2>
          <div className="result-details text-xl w-auto h-auto">
            <p>{convertedText}</p>
          </div>
        </div>
      )}
    </div>
    <div className='mt-5 mb-5 ml-2'>
      <p className='text-xl mt-2'>
      The Uppercase to Lowercase Converter tool simplifies the process of transforming uppercase alphabets into their corresponding lowercase counterparts. Developed using React, this user-friendly application offers a straightforward interface. Users input text in uppercase, and with the click of a button, the tool swiftly converts it to lowercase.
      </p>
      <p className='mt-10 text-xl'>
      Its purpose extends beyond the conversion, aiming to provide a seamless and efficient experience. Whether for casual use or integration into larger applications, this tool showcases the versatility of React in creating practical solutions. Its straightforward design ensures accessibility, making it a handy resource for anyone requiring quick and easy alphabet case transformation in a web-based environment.
      </p>
    </div>
    </section>
  );
};

export default UppercaseToLowercaseConverter;
