import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuysellads, faExpeditedssl, faPaypal, faSearchengin, faSlackHash, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faA, faArrowUp91, faBolt, faChartSimple, faClock, faCode, faCompress, faDesktop, faDroplet, faEarthAmericas, faEraser,  faFileImage, faFilePdf,  faGauge,  faIdCard, faImage, faImages, faKey, faLocationDot, faMoneyBill, faParagraph, faPenNib, faPercent, faQrcode, faRobot, faRulerCombined,  faSpellCheck,  faTextWidth,  faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <main className='sm:bg-slate-300 h-auto sm:h-[1010px]  sm:w-[80%] sm:ml-[3%] mt-[10%] rounded mb-[15%]'>
      <h1 className='text-3xl font-bold text-center mt-2'>Online Tools</h1>
      <section className='grid grid-cols-1 sm:grid-cols-5 px-20 sm:px-0 sm:ml-[50px] mt-5 gap-y-5 '>
        <Link to="/plagiarism-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
            <FontAwesomeIcon size='2xl' className='py-2'  icon={faSearchengin} />
            <h2 className='font-semibold text-[16px] text-center'>Plagiarism Checker</h2>
          </div>
        </div>
        </Link>
        
        <Link to="/article-rewriter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faPenNib} /> 
            <h2 className='font-semibold text-[16px] text-center'>Article</h2>
            <span className='font-semibold text-[16px] text-center'>Rewriter</span>
          </div>
        </div>
        </Link>
        
        <Link to="/grammer-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faSpellCheck} /> 
            <h2 className='font-semibold text-[16px] text-center'>Grammer</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>
       
       <Link to="/word-counter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faArrowUp91} /> 
            <h2 className='font-semibold text-[16px] text-center'>Word</h2>
            <span className='font-semibold text-[16px] text-center'>Counter</span>
          </div>
        </div>
        </Link>
        
        <Link to="/spelling-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faBuysellads} /> 
            <h2 className='font-semibold text-[16px] text-center'>Spell</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>
        
        <Link to="/essay-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faParagraph} /> 
            <h2 className='font-semibold text-[16px] text-center'>Eassy</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>

        
        <Link to="/md5-generator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faSlackHash} /> 
            <h2 className='font-semibold text-[16px] text-center'>MD5</h2>
            <span className='font-semibold text-[16px] text-center'>Generator</span>
          </div>
        </div>
        </Link>
        
        <Link to="/uppercase-to-lowercase-converter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faA} /> 
            <h2 className='font-semibold text-[15px] text-center'>Uppercase to</h2>
            <span className='font-semibold text-[16px] text-center'>Lowercase</span>
          </div>
        </div>
        </Link>
         
         <Link to="/word-combiner">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faRulerCombined} /> 
            <h2 className='font-semibold text-[16px] text-center'>Word</h2>
            <span className='font-semibold text-[16px] text-center'>Combiner</span>
          </div>
        </div>
        </Link>

        <Link to="/text-to-image">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faFileImage} /> 
            <h2 className='font-semibold text-[16px] text-center'>Text to</h2>
            <span className='font-semibold text-[16px] text-center'>Image</span>
          </div>
        </div>
        </Link>
        
        <Link to="/jpg-to-png">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faImage} /> 
            <h2 className='font-semibold text-[16px] text-center'>JPG to</h2>
            <span className='font-semibold text-[16px] text-center'>PNG</span>
          </div>
        </div>
        </Link>
        
        <Link to="/resume-maker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faIdCard} /> 
            <h2 className='font-semibold text-[16px] text-center'>Resume</h2>
            <span className='font-semibold text-[16px] text-center'>Maker</span>
          </div>
        </div>
        </Link>
        
        <Link to="/image-compressor">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faImages} /> 
            <h2 className='font-semibold text-[16px] text-center'>Image</h2>
            <span className='font-semibold text-[16px] text-center'>Compressor</span>
          </div>
        </div>
        </Link>
        
        <Link to="/pdf-to-image">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faFilePdf} /> 
            <h2 className='font-semibold text-[16px] text-center'>PDF to</h2>
            <span className='font-semibold text-[16px] text-center'>Image</span>
          </div>
        </div>
        </Link>
       
       <Link to="/rgb-to-hex">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faDroplet} /> 
            <h2 className='font-semibold text-[16px] text-center'>RGB to</h2>
            <span className='font-semibold text-[16px] text-center'>HEX</span>
          </div>
        </div>
        </Link>
        
        <Link to="/seo-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faChartSimple} /> 
            <h2 className='font-semibold text-[16px] text-center'>SEO</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>
 
        <Link to="/text-to-voice">
        <div> 
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faTextWidth} /> 
            <h2 className='font-semibold text-[16px] text-center'>Text to</h2>
            <span className='font-semibold text-[16px] text-center'>Voice</span>
          </div>
        </div>
        </Link>
        
        <Link to="/screen-resolution-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faDesktop} /> 
            <h2 className='font-semibold text-[16px] text-center'>Resolution</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>
       
       <Link to="/internet-speed-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faGauge} /> 
            <h2 className='font-semibold text-[16px] text-center'>Internet Speed</h2>
          </div>
        </div>
        </Link>
        
        
        
       
        <Link to="/robots-txt-generator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faRobot} /> 
            <h2 className='font-semibold text-[16px] text-center'>Robots.txt</h2>
            <span className='font-semibold text-[16px] text-center'>Generator</span>
          </div>
        </div>
        </Link>
       
       <Link to="/ssl-checker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faExpeditedssl} /> 
            <h2 className='font-semibold text-[16px] text-center'>SSL</h2>
            <span className='font-semibold text-[16px] text-center'>Checker</span>
          </div>
        </div>
        </Link>

        <Link to="/what-is-my-ip">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faLocationDot} /> 
            <h2 className='font-semibold text-[16px] text-center'>What's My</h2>
            <span className='font-semibold text-[16px] text-center'>IP</span>
          </div>
        </div>
        </Link> 
        
        <Link to="/qr-code-maker">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faQrcode} /> 
            <h2 className='font-semibold text-[16px] text-center'>QR Code Maker</h2>
          </div>
        </div>
        </Link>
        
       
        
       <Link to="/png-to-jpg">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faImage} /> 
            <h2 className='font-semibold text-[16px] text-center'>PNG to</h2>
            <span className='font-semibold text-[16px] text-center'>JPG</span>
          </div>
        </div>
        </Link>

        <Link to="/meta-tag-generator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faCode} /> 
            <h2 className='font-semibold text-[16px] text-center'>Meta Tag</h2>
            <span className='font-semibold text-[16px] text-center'>Generator</span>
          </div>
        </div>
        </Link>
        
        <Link to="/password-generator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faKey} /> 
            <h2 className='font-semibold text-[16px] text-center'>Password</h2>
            <span className='font-semibold text-[16px] text-center'>Generator</span>
          </div>
        </div>
        </Link>
       
        <Link to="/image-to-pdf">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faFilePdf} /> 
            <h2 className='font-semibold text-[16px] text-center'>Image to</h2>
            <span className='font-semibold text-[16px] text-center'>PDF</span>
          </div>
        </div>
        </Link>
        
        <Link to="/compress-pdf">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faCompress} /> 
            <h2 className='font-semibold text-[16px] text-center'>Compress</h2>
            <span className='font-semibold text-[16px] text-center'>PDF</span>
          </div>
        </div>
        </Link>

     
        
        <Link to="/speed-converter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faTruckFast} /> 
            <h2 className='font-semibold text-[16px] text-center'>Speed</h2>
            <span className='font-semibold text-[16px] text-center'>Converter</span>
          </div>
        </div>
        </Link>
        
        <Link to="/area-converter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faEarthAmericas} /> 
            <h2 className='font-semibold text-[16px] text-center'>Area</h2>
            <span className='font-semibold text-[16px] text-center'>Converter</span>
          </div>
        </div>
        </Link>
       
       <Link to="/voltage-converter">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faBolt} /> 
            <h2 className='font-semibold text-[16px] text-center'>Volatge</h2>
            <span className='font-semibold text-[16px] text-center'>Converter</span>
          </div>
        </div>
        </Link>
      
       <Link to="/percentage-calculator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faPercent} /> 
            <h2 className='font-semibold text-[16px] text-center'>Percentage</h2>
            <span className='font-semibold text-[16px] text-center'>Calculator</span>
          </div>
        </div>
        </Link>

        
        <Link to="/salary-calculator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faMoneyBill} /> 
            <h2 className='font-semibold text-[16px] text-center'>Salary</h2>
            <span className='font-semibold text-[16px] text-center'>Calculator</span>
          </div>
        </div>
        </Link>
        
        <Link to="/paypal-fee-calculator">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faPaypal} /> 
            <h2 className='font-semibold text-[16px] text-center'>Paypal Fee</h2>
            <span className='font-semibold text-[16px] text-center'>Calculator</span>
          </div>
        </div>
        </Link>
       
       <Link to="/image-background-remover">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faEraser} /> 
            <h2 className='font-semibold text-[16px] text-center'>Background</h2>
            <span className='font-semibold text-[16px] text-center'>Remover</span>
          </div>
        </div>
        </Link>
        
        <Link to="/tiktok-downloader">
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faTiktok} /> 
            <h2 className='font-semibold text-[16px] text-center'>Video</h2>
            <span className='font-semibold text-[16px] text-center'>Downloader</span>
          </div>
        </div>
        </Link>
        
       <Link to="/time-calculator"> 
        <div>
          <div className='bg-slate-100 sm:w-[50%] rounded-lg flex flex-col h-[100px] hover:border-[2px] border-[#001f3f]'>
          <FontAwesomeIcon size='2xl' className='py-2' icon={faClock} /> 
            <h2 className='font-semibold text-[16px] text-center'>Time</h2>
            <span className='font-semibold text-[16px] text-center'>Calculator</span>
          </div>
        </div>
        </Link>


        
      </section>
    </main>
  );
}

export default Home;
