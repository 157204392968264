import React, { useState } from 'react';

const WordCounter = () => {
  const [wordCount, setWordCount] = useState(null);

  function countWords() {
    var text = document.getElementById("text").value;
    text = text.trim();
    var words = text.split(/\s+/);
    setWordCount(words.length);
  }

  return (
    <section className='flex flex-col '>
      <div className='text-3xl font-bold text-center mt-5'>
        <h1>Word Counter</h1>
      </div>

      <div>
        <textarea className='border-2 sm:ml-[30%] mt-5 px-2 w-[320px] sm:w-[600px] ml-5 rounded border-[#001f3f]' placeholder='Enter your paragraph' id="text" rows="10" cols="80"></textarea>
      </div>

      <div className='text-center mt-5 mb-5'>
        <button className='text-white bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg' onClick={countWords}>Count Words</button>
      </div>

      {wordCount !== null && (
        <div className='text-xl ml-2'>
          <p className='text-xl font-semibold text-center'>Number of Words: <span id="wordCount" className='font-bold text-xl'>{wordCount}</span></p>
        </div>
      )}

      <div className='ml-2 mb-5'>
        <h1 className='text-3xl font-bold mt-10'>Master the Art of Precision with Our Word Counter Tool</h1>

        <h2 className='text-2xl font-semibold mt-5'>
        Unveiling the Word Counter Wizardry

        </h2>

        <p className='text-xl mt-2'>
        Welcome to the Word Counter Tool—an indispensable companion for writers, students, and professionals seeking to harness the power of precision in their textual creations. In a world where every word matters, our tool empowers you to meticulously count and analyze the words in your paragraphs, ensuring clarity, accuracy, and a refined writing style.



        </p>

        <h2 className='text-2xl font-semibold mt-5'>
        Seamlessness in Word Counting
        </h2>

        <p className='text-xl mt-2'>
        Gone are the days of manual word counting or relying on clunky tools. Our Word Counter is a beacon of simplicity and effectiveness. With an intuitive user interface, all it takes is a few clicks to gain insights into the structure and composition of your text. No more second-guessing or tedious counting; let our tool do the heavy lifting for you.
        </p>
      </div>
    </section>
  );
}

export default WordCounter;
