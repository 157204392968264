import React, { useState } from 'react';
import axios from 'axios';

const FacebookVideoDownloader = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoData, setVideoData] = useState(null);

  const handleInputChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const options = {
      method: 'GET',
      url: 'https://facebook-video-audio-download.p.rapidapi.com/geturl',
      params: {
        url: encodeURIComponent(videoUrl),
      },
      headers: {
        'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
        'X-RapidAPI-Host': 'facebook-video-audio-download.p.rapidapi.com',
      },
    };
  
    try {
      const response = await axios.request(options);
      setVideoData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label>
          Enter Facebook Video URL:
          <input type="text" value={videoUrl} onChange={handleInputChange} />
        </label>
        <button type="submit">Submit</button>
      </form>

      {videoData && (
        <div>
          <video controls width="400" height="300">
            <source src={videoData.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <a href={videoData.video_url} download>
            <button>Download Video</button>
          </a>
        </div>
      )}
    </div>
  );
};

export default FacebookVideoDownloader;
